@import "../../index.module";

.step-container {
  // width: 96%;
  height: 705px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-left: 2px;
  margin-top: 16px;
}

.first-col {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 340px;
}

.main-label {
  width: 150px;
  height: 24px;
  opacity: 0.87;
  font-family: "Roboto", sans-serif !important;
  font-size: 20px !important;
  font-weight: normal !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal !important;
  letter-spacing: normal;
  color: #000000 !important;
}

.main-field {
  width: 328px;
  height: 48px;
  margin-top: 9px !important;
  margin-bottom: 0px !important;
}

.step-header {
  width: 100%;
  position: relative;
  padding: 17px 0;
  background-color: #343e48;
  -webkit-box-shadow: 0 2px 4px 0 rgba(189, 144, 144, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  button {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 25px;
    cursor: pointer;
  }

  i {
    width: 18px;
    height: 18px;
    margin: 0px 11px;
    display: inline-block;
    background-position: 0;
    &.back-icon {
      background: url("../../assets/icons/back.png") no-repeat;
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
}
.button-container {
  button {
    margin: 10px;
  }
}
.button-container {
  display: inline-block;
  float: right;
}
.step-rightside {
  height: 380px;
  font-family: "Roboto", sans-serif;
  font-size: 150px;
  padding-top: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  overflow-y: auto;
  overflow-x: hidden;
}
.step-req {
  margin-left: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
}
.step-content {
  width: 96%;
  margin: 20px;
  overflow: auto;
}
.divider-shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}
.vertical-divider {
  height: 463px;
  padding: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.5);
}

.col-md-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-5ths {
  width: 20%;
  float: left;
}
.alerttype {
  font-weight: bold;
  padding: 5px !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .col-md-5ths {
    width: 100%;
    float: left;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .col-md-5ths {
    width: 50%;
    float: left;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .col-md-5ths {
    width: 50%;
    float: left;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .col-md-5ths {
      width: 25%;
      float: left;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .col-md-5ths {
      width: 20%;
      float: left;
    }
  }
}
// to remove box outline of back in alterstepper
.no-focus:focus {
  outline: none;
}
// opacity of text  in alterstepper
.text-op {
  opacity: 0.35;
  filter: Alpha(opacity=35);
}
// paddding between text in  in altestepper
.div-pad {
  padding-top: 25px;
}
// width of text box in alterstepper
.text-wid {
  width: 328px;
}
// left paddding to textfield in  in fuel alert altestepper
.left-pad {
  padding-left: 38px;
}
.in-pad {
  padding-top: 28px;
  // align-self: auto;
}
.inn-pad {
  padding-top: 10px;
}
.fuel-alertradio label {
  flex-direction: unset;
}
