@import '../../index.module.scss';

.header_btn_container{
  display: flex;
  float: right;
  margin-right: 15px;
  margin-top: -39px;
}

.account_settings{
  z-index: 3;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: -0.5px;
  color: #ffffff;
  background-color: transparent;
  outline: none;
  i {
    width: 18px;
    height: 18px;
    margin: 0px 11px;
    display: inline-block;
    background-position: 0;
    &.back-icon {
      background: url("../../assets/icons/back.png") no-repeat;
    }
    &.user-icon {
      background: url("../../assets/icons/account.svg") no-repeat;
    }
  }

  span {
    max-width: 100px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.app_notifications {
  cursor: pointer;
  outline: none;
  position: relative;
  z-index: 3;
}

.notification_icon_circle {
  height: 10px;
  margin-left: -12px;
  position: absolute;
  margin-top: 7px;
  width: 10px;
}

.notification_icon_circle_txt {
  font-size: 8px;
  font-weight: bold;
  margin-left: -9px;
  position: absolute;
  margin-top: 8px;
}

.plain-header {
    width: 100%;
    position: relative;
    padding: 8px 0;
    background-color: #343e48;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    height: 32px;
    top: 0;
    right: 0;
    margin-right: 15px;
    .account_settings {
      float: right;
      margin-right: 15px;
    }
    .back-button {
      float: left;
      margin-right: 25px;
      border: none;
      padding: 5px 10px;
      text-decoration: none;
      font-size: 16px;
      margin-top: 0px;
      position: relative;
      cursor: pointer;
      height: 22px;
      // font-family: $themeFont;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      text-align: left;
      color: #ffffff;
      outline: none;
      background-color: transparent;
      i {
        width: 18px;
        height: 18px;
        margin: 0px 11px;
        display: inline-block;
        background-position: 0;
        &.back-icon {
          background: url("../../assets/icons/back.png") no-repeat;
        }
        &.user-icon {
          background: url("../../assets/icons/account.svg") no-repeat;
        }
      }

      span {
        display: inline-block;
        top: -3px;
        position: relative;
      }
    }
}
