@import "../../index.module";
.content-wrapper {
    margin: 20px 20px 5px 10px;
}
.header-wrapper {
    background-color: #343e48;
    display: flex;
    padding: 20px;
}
.header-pull-right {
    margin-left: auto;
}
.header-text {
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #ffffff;
}
.required-text {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    vertical-align: middle;
}
.back-arrow-icon {
    background: url("../../assets/icons/back-arrow-white.svg") no-repeat;
    width: 3.7%;
    margin-top: 3px;
    border: none;
    cursor: pointer;
    outline: none;
}

.textField {
    width: 380,
}

.content-footer {
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
}

.action-buttons-wrapper {
    margin: 12px 10px 10px auto;
}

.cancel-button-label {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: right;
    margin-right: 10px !important;
    color: rgba(0, 0, 0, 0.87);
    min-height: 36px;
}

.save-button-label {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
}

.available-heading-content {
    opacity: 0.87;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    white-space: nowrap;
}

.available-page-info {
    opacity: 0.63;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-top: -8px;
}

.available-table {
    overflow-y: 'scroll',
}

.available-table-header {
    opacity: 0.54;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
}

.selected-heading-text {
    opacity: 0.87;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    width: 83%;
}

.table-th {
    text-align: left;
    min-width: 33px !important;
}

.table-tr {
    height: 48px;
    opacity: 0.87;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.checkbox-td {
    min-width: 33px !important;
}

.available-container {
    margin-left: 5px;
}

.asset-search-box {
    width: 150px;
    box-sizing: border-box;
    font-size: 16px;
    background-color: white;
    background-image: url('../../assets/icons/search.svg');
    background-position: 10px 10px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    border: 0;
    outline: 0;
    padding-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.asset-search-box:focus {
    width: 100%;
    border-bottom: 1px solid #CBC7C6;
}

.asset-search-wrapper {
    width: 60%;
}

.subgroup-text {
    opacity: 0.63;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: -3px;
}

.selected-page-info {
    opacity: 0.63;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.resetText {
    color: #007aff;
}
.diabledResetText {
    color: grey;
}

