@import "../../../index.module";

.right-dropdown-holder {
  width: 485px;
  border-radius: 0px 4px 4px 4px;
  padding: 8px 12px 12px 12px;
  background-color: $grey;
}

.line {
  width: 407px;
  height: 2px;
  opacity: 0.28;
  border: solid 1px $border_line;
  margin: 3px 0;
}

.right-dropdown-inner {
  border-radius: 2px;
  background-color: $white;
  padding: 18px 27px 40px 27px;
  /*min-height: 565px;*/
  ul {
    margin-left: 16px;
    li {
      margin-bottom: 3px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      color: $green;
    }
  }
}

.pull-left {
  float: left;
}

.heading {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: $green;
}

.sub-heading {
  font-size: 14px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: $green;
  margin-bottom: 4px;
  padding: 3px 0;
}

.content {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: $green;
}
