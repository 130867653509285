@import "../../index.module";

.address-details {
  position:relative;
  flex-grow: 1;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 0 10px -2px #4a4a4a;
  width: 370px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;

  .address-header {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.5px;
    white-space: normal;
  }

  .sub-header {
    opacity: 0.73;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #ffffff;
    white-space: normal;
  }

  .address {
    opacity: 0.87;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #ffffff;
  }

  .card {
    width: 100%;
    min-height: 70px;
    -webkit-backdrop-filter: blur(2.3px);
    backdrop-filter: blur(2.3px);
    background: #343e48;
    position: relative;
    box-shadow: none;
    border-radius: 0;
    .card-content {
      padding: 15px;
      .card-content-bottom {
        position: absolute;
        width: 360px;
        bottom: 0;
        .phone {
          display: inline-block;
          width: 45%;
          opacity: 0.73;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.4px;
          color: #ffffff;
        }
      }
    }
  }
}