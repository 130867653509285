@import "../../../index.module";

.logo {
    width: 226px;
}

.search-dropdown-holder {
    padding-bottom: 8px;
    border-bottom: 1px solid #d3d7db;
}

.searchIcon {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
    color: #767676;
    cursor: pointer;
}

.search-dropdown-holder.focused {
    background: #d4d4d4 !important;
}

.results-window {
    width:100%;
    max-height:276px;
    z-index: 1;
    position: absolute;
}

.results-table {
    max-height:220px;
    overflow-x: auto;
}

.tableWrapper {
    overflow-x: scroll;
    max-height: 220px;
}

.search-bar {
    input{
        float: left;
        padding-top: 0px;
        padding-bottom: 0px;
        width:100%;
        position: relative;
        z-index: 12;
    }
}

.search-icon{
    background: url("../../../assets/icons/search.svg") no-repeat;
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.search-box-error-container{
    font-size: 18px;
    text-align: right;
    position: absolute;
    right: 0;
    z-index: 10;
    .error-msg{
        font-size: 14px;
        margin-right: 14px;
        height: 17px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #d91e18;
        display: inline-block;
        vertical-align: top;
    }
    .error-icon {
        background: url("../../../assets/icons/box-important.svg") no-repeat;
        width: 14px;
        height: 14px;
        display: inline-block;
        margin-right: 9px;
        padding: 13px 8px 13px;
    }
}



