@import "../../index.module";

.dropdown-holder {
  width: 768px;
  border-radius: 0 0px 2px 2px;
  background-color: $white;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 12px 15px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  right: 0;
  margin: -2px auto;
}
.dropdown-inner-holder {
  width: 738px;
}

.container-box {
  padding: 0;
  .right-dropdown-inner {
    border-radius: 4px 4px 0 0;
    background-color: $grey;
    padding: 5px 10px 0 10px;
  }
  padding-bottom: 7px;
  padding-left: 0px;
  padding-right: 0px;
}
.right-dropdown-holder {
  width: 509px;
  border-radius: 0px 4px 4px 4px;
  padding: 8px 12px 12px 12px;
  background-color: $grey;
}
.pull-left {
  float: left;
 /* height: 645px;*/
}




.truck {
  background: url("../../assets/icons/assets.svg") no-repeat;
}


.asset-group {
  background: url("../../assets/icons/asset-group.png") no-repeat;
}


.landmarks {
  background: url("../../assets/icons/landmarks.svg") no-repeat;
}

.landmarks-group {
  background: url("../../assets/icons/landmark-group.png") no-repeat;
}

.shape {
  background: url("../../assets/icons/drivers.svg") no-repeat;
}

.pointers {
  background: url("../../assets/icons/address.svg") no-repeat;
}

.spinner {
  background: url("../../assets/icons/addresses/spinner.gif") no-repeat;
  width: 24px;
  height: 24px;
}
.list-items {
  object-fit: contain;
  display: table-cell;
  vertical-align: middle;
  padding-left: 4px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: unset;
  letter-spacing: -0.4px;
  color: $green;
  margin-top: 7px;
}

.line {
  width: 407px;
  height: 2px;
  opacity: 0.28;
  border: solid 1px $border_line;
  margin: 3px 0;
}

.right-dropdown-inner {
  border-radius: 4px 4px 0 0;
  padding: 0;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  width: calc(100% + 15px);
  background-color: $white;
  box-sizing: border-box;
  ul {
    margin-left: 16px;
    li {
      margin-bottom: 3px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      color: $green;
    }
  }
}

.left-dropdown-holder {
  ul {
    li {
      width: 100%;
      clear: both;
      height: 40px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: -0.4px;
      padding: 0px 15px 0 10px;
      color: $green;
      display: flex;
      cursor: pointer;
      i {
        width: 24px;
        height: 24px;
        object-fit: contain;
        display: table-cell;
        vertical-align: middle;
        background-position: 0;
      }
    }
    li.active {
      background: $grey;
      border-radius: 4px 0 0 4px;
    }
    list-style: none;
  }
  width: 228px;
  box-sizing: border-box;
}
.image {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 23px;
  background: red;
}
.dropdown-number {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
  width: 40px;
  color: $green;
  display: table-cell;
  vertical-align: middle;
  padding-top: 3px;
}
.pull-left {
  float: left;
}
.clearfix {
  clear: both;
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}
.heading {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: $green;
}
.sub-heading {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: $green;
  margin-bottom: 4px;
  padding: 3px 0;
}
.content {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: $green;
}


.search-bar,
.dropdown-holder {
  position: absolute;
  top: 50px;
  left: 0;
}

.dropdown-holder {
  z-index: 10;
}
  .predictions-count {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #4a4a4a;
    margin-top: 8px;
    margin-bottom: -4px;
    text-align: center;
  }
li:last-child{
  margin-bottom: 0px !important;
}
