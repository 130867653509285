@import "../../index.module";

.container {
    .asset-header {
        color: #ffffff;
        font-family: $themeFontMediumBold;
        font-size: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        width: 280px;
    }

    .asset-header-name {
        color: #ffffff;
        font-family: $themeFontMediumBold;
        font-size: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        width: auto;
        max-width: 250px;
    }

    .sub-header {
        opacity: 0.73;
        font-family: inherit;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #ffffff;
    }

    .address {
        opacity: 0.87;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #ffffff;
    }

    .card {
        width: 100%;
        min-height: 174px;
        -webkit-backdrop-filter: blur(2.3px);
        backdrop-filter: blur(2.3px);
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1)), url('../../assets/assets_bg_image.png');
        position: relative;
        box-shadow: none;
        border-radius: 0;

        .card-content {
            padding: 15px;

            .card-content-bottom {
                width: 352px;
                margin-top: 10px;

                .lastlocation {
                    display: inline-block;
                    width: 45%;
                    opacity: 0.73;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: -0.4px;
                    color: #ffffff;
                }

                .status {
                    display: inline-block;
                    width: 50%;
                    opacity: 0.73;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: -0.4px;
                }

                .statusText {
                    text-transform: uppercase;
                }
            }
        }

        .insufficient_data {
            background-color: #d8d8d8;
            color: #343e48;
            font-size: 12px;
            height: 34px;
            margin-top: 28px;
            text-align: center;
            width: 100%;

            .insufficient_text {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }

    .back_button {
        flex: auto;
        border: none;
        padding-left: 2px;
        padding-top: 3px;
        text-decoration: none;
        margin-top: 0px;
        position: relative;
        cursor: pointer;
        height: 22px;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        text-align: left;
        color: #ffffff;
        outline: none;
        background-color: transparent;

        i {
            width: 18px;
            height: 18px;
            display: inline-block;
            background-position: 0;

            &.back_icon {
                background: url("../../assets/icons/back.png") no-repeat;
            }
        }

        span {
            display: inline-block;
            top: -3px;
            position: relative;
        }
    }
}

.dashcam_status {
    position: relative;
    top: -3px;
    right: -10px;

    .dashcam_status_icon {
        position: relative;
    }
}

.detailLoader {
    margin: 50px;
    text-align: center;
}
