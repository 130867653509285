.home-content-wrapper {
    position: relative;
}

.map-top-info {
    background-color: #f5f5f5;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    height: 39px;
    right: 0;
    top: 0;
    position: absolute;
    transform: translateX(0%);
    width: 373px;
    z-index: 1300;
}

.info-icon-container {
    height: 39px;
    &:hover{
        background-color: #e1e1e1;
    }
}

.info-icon {
    display: flex;
    padding: 10px 9px;
    img {
        width: 19px;
        height: 19px;
    }
    span {
        color: #777777;
        font-size: 14px;
        letter-spacing: 0.1px;
        margin-left: 10px;
        width: 25px;
    }
}

.selected-filter {
    background-color: #e1e1e1;
}

.loader {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    top: 0px;
    > div > div {
        position: fixed;
        top: 0;
        width: 100%;
    }
}

.disable-filter {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.33);
    z-index: 1301;
    opacity: 0.5;
}
