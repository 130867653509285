.labelContainer {
    top: -34px;
    position: relative;
    left: 23px;
    border-radius: 2px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.zoomedOut {
      top: -29px;
      left: 6px;
    }
    &.middleZoom {
      top: -20px;
      left: 10px;
    }
}
.labelContainer:hover {
    overflow: visible; 
    max-width: 100%;
}
.label {
    font-size: 10px;
    line-height: 16px;
    padding-left: 4px;
    padding-right: 4px;
}
.arrowIcon {
  filter: drop-shadow(0 2px 4px rgba(0,0,0, 0.5));
  -ms-filter: drop-shadow(0 2px 4px rgba(0,0,0, 0.5));
  -moz-filter: drop-shadow(0 2px 4px rgba(0,0,0, 0.5));
  -webkit-filter: drop-shadow(0 2px 4px rgba(0,0,0, 0.5));
  margin: -12px;
}
.startIcon {
  margin: -12px;
  z-index: 999;
  position: absolute;
}
.stopIcon {
  margin: -12px;
  z-index: 1000;
  position: absolute;
}
.arrowIconAnim {
  animation: pulsate .4s ease-in 0s alternate infinite;
  filter: drop-shadow(0 6px 3px rgba(0,0,0, 0.24));
  -ms-filter: drop-shadow(0 6px 3px rgba(0,0,0, 0.24));
  -moz-filter: drop-shadow(0 6px 3px rgba(0,0,0, 0.24));
  -webkit-filter: drop-shadow(0 6px 3px rgba(0,0,0, 0.24));
  height: 24px;
  position: absolute;
  margin: -13px;
  width: 24px;
  z-index: 1001;
}
.controls {
  font-size: 28px;  /* this adjusts the size of all the controls */
  background-color: white;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  height: 40px;
  display: table-cell;
  vertical-align: middle;
  position: relative;
  color: rgb(0, 0, 0);
  margin: 6px;
  user-select: none;
  padding: 0px 17px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 36px;
  font-weight: 500;direction: ltr;
  overflow: hidden;
  text-align: center;
  &.maptype-control {
    display: flex;
    flex-direction: row;
    width: 291px;
    border-bottom: solid 2px #fafafa;
    top: 16px !important;
    left: 4px !important;
  }
}

.maptype-control-map {
  font-weight: 700;
}

.maptype-control-custom {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.custom-view-control {
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  width: 123px;
  height: 40px;
  z-index: 1 !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  left: 178px !important;
  top: 62px !important;
}

.hidden-custom-view-control {
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  width: 123px;
  height: 40px;
  z-index: 1 !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  left: 180px !important;
  top: 10px !important;
}

.customControlIconStyle {
  background-color: rgb(255, 255, 255) !important;
  &:hover {
      background-color: #D8D8D8 !important;
  }
}

.assetClusters {
    border: none !important;
    outline: none !important;
    margin: -58px 0 0 -24px;
}

.landmarkClusters {
    border: none !important;
    outline: none !important;
    margin: -62px 0 0 -28px;
}

.overlayPos_1 {position: absolute; z-index: 5;}
.overlayPos_2 {position: absolute; z-index: 4;}
.overlayPos_3 {position: absolute; z-index: 3;}
.overlayPos_4 {position: absolute; z-index: 2;}
.overlayPos_5 {position: absolute; z-index: 1;}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.34);
  }
}

@media print {
  .maptype-control-custom {
    display: none !important;
  }
}

.pulse {
  position: absolute;
  margin-top: -50px;
  margin-left: -50px;
  transform: rotateX(55deg);

  &:after {
    display: block;
    width: 100px;
    height: 100px;
    content: '';
    animation: halo 1s ease-out infinite;
    -webkit-animation: halo 1s ease-out infinite;
    -moz-animation: halo 6s ease-out infinite;
    opacity: 0;
    border-radius: 50%;
  }
}

@mixin pulse($color) {
  @extend .pulse;

  &:after {
    border: 2px solid $color;
    box-shadow: 0 0 6px 3px $color;
  }
}

.pulse-7ed321 { @include pulse(#7ed321); }

.pulse-f5a623 { @include pulse(#f5a623); }

.pulse-fc0207 { @include pulse(#fc0207); }

.pulse-9E9E9E { @include pulse(#9E9E9E); }

.pulse-007aff { @include pulse(#007aff); }


@keyframes halo {
  0% {
      transform: scale(.1, .1);
      opacity: 1;
  }
  50% {
      opacity: 1;
  }
  100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
  }
}

.satIcon {
  width: 18px;
  height: 18px;
  padding-top: 8px;
}

.gpsIcon {
  padding-bottom: 6px;

  >img {
    padding-top: 3px !important;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.infoWindow {

  .icon {
    background-color: #eeeeee;
    height: 90px;
    width: 28px;
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .container {
    padding-left: 14px;
    padding-top: 4px;

    .eventName {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.15;
    }

    .operatorName {
      line-height: 1.36;
      font-size: 14px;
      letter-spacing: 0.1;
      color: rgba(0, 0, 0, 0.83);
    }

    .eventDate {
      line-height: 1.36;
      font-size: 14px;
      letter-spacing: 0.1;
      color: rgba(0, 0, 0, 0.83);
      font-weight: 500;
      padding-top: 2px;
    }

    .speed {
      color: #e02020;
      font-weight: 600;
      font-size: 14px;
    }

    .heading {
      color: rgba(0, 0, 0, 0.64);
      font-size: 14px;
    }

    .speedLimit {
      color: rgba(0, 0, 0, 0.64);
      font-size: 14px;
      font-weight: 300;
    }

    .address {
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      letter-spacing: 0.1;
    }
  }
}

.direction-arrow {
  position: relative;
  width: 20px !important;
  height: 20px !important;
  color: #fff;
  z-index: 1300;
};
