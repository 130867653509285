@import "../../../index.module";

.header {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    p {
        display: inline-flex;
        padding-left: 34px;
    }
    button {
        margin: -6px 0 0 14px;
    }
}
.body {
    padding: 0 20px 20px 0;
    min-height: calc(100vh - 208px);
    ul {
        margin: -10px 0 0 -24px;
    }
    li {
        font-size: 12px;
    }
    .highlight {
        font-size: 12px;
        color: #f00;
        margin: 10px 0;
    }
    .subHeader {
        font-weight: bold;
        color: #000;
        margin: 10px 0;
    }
    .plainText {
        color: #4a4a4a;
        margin: 10px 0;
    }
    .uploadButton {
        align-self: flex-end;
        margin-bottom: 50px;
    }
    > div > div:first-child {
        text-align: center;
        margin: 18px 0;
        align-self: auto;
        > svg {
            width:126px;
            height: 126px;
            cursor: pointer;
        }
        div {
            > label {
                display: flex;
            }
            > svg {
                width:126px;
                height: 126px;
                cursor: pointer;
            }
        }
    }
}
