@import '../../../index.module.scss';

.event_card_holder {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

  .eventCard {
    border-radius: 0;
    box-shadow: none;
    cursor: inherit;
    padding-left: 6px;

    .eventCardHeader {
      padding: 10px 8px 7px 10px;

      .tooltip {
        display: inline-block;
        font-size: 18px;
        left: 46px;
        position: absolute;
        top: 15px;
        z-index: 2;
      }
    }
  }

  .eventCard:hover {
    background-color: #d8d8d8;
  }

  .event_name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 3.6em;
    display: block;
    max-width: 90px;
  }

  .name_Wrap {
    padding-right: 6px;
  }

  .history_count {
    font-size: 14px;
    color: #000000;
    padding-left: 8px;
  }

  .count {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 8px;
    height: 24px;
    margin: 13px 0px 11px 0px;
  }

  .equal {
    line-height: 1.71;
    font-size: 14px;
  }

  .event_icon {
    width: 48px;
    height: 48px;
  }

  .event_count {
    font-size: 32px;
    line-height: 0.9;
    color: #000000;
  }

  .event_name_btn {    
    .event_name_container {
      width: 48px;
      height: 48px;
    }

  

    .event_date {
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      max-width: 210px;
    }
    .event_video_icon {
      padding-left: 20px;
      cursor: pointer;
    }
  }
}

.event_card_holder_selected {
  background-color: rgba(0, 122, 255, 0.2);
}

