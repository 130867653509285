.filterItems {
  margin-top: 32px;
}
.filterItem div {
  width: 100%;
  margin-top: 2px;
}
.datePickercontainer {
  margin-top: 10px;
}
button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 25px;
  cursor: pointer;
}
.assetFilter {
border-bottom: #ffffff;
}
.filterItemDate div {
  width: 100%;
}
