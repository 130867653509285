.customStyle > div {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.customStyle {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.33);
    position: fixed;
    z-index: 1202;
    top:0;
}