.full-width {
    position: relative;
    height: 100%;
}

.dashboard-button-header {
    z-index: 3;
    padding-top:17px;
    padding-right:20px;
    padding-bottom:20px;
    position: absolute;
    width: calc(100% - 276px);
    overflow: hidden;
}

.clear-fix {
    clear:both;
}

.dashboard-content-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5px;
}

.iframe {
    margin-top:-30px;
}

.no-content-dialog-container{
    padding-top: 230px;
    text-align: center;
    top: 50%;
    height: 100%;
    width: 100%;

}

.no-content-dialog-panel{
    -webkit-box-shadow: 10px 10px 25px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 25px -10px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 25px -10px rgba(0,0,0,0.75);
    margin: auto;
    border: 'thin solid #F0F0F0';
    padding: 50px;
    color: 'black';
    width: 500px;
}

.download-popover-paper {
    padding-top: 5px;
    padding-left: 15px;
    padding-bottom: 5px;
    color: #4d4d4e;
    display: inline-block;
    position: relative;
    outline: none;
}

.emph-text{
    font-weight:bold;
}

.edit-dashboard-button {
    float: left;
    position: relative;
    margin-left: 20px;
    margin-top: 5px;
}