@import "../../index.module";
.container{
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 24px;
    margin-top: 20px;
    
    .heading {
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
        padding-left: 27px;
        padding-top: 14px;
        
        .heading-sub {
            color: rgba(0, 0, 0, 0.63);
        }
    }

    .advanceScoringWrapper {
        width: 22%;
        float: right;
        min-width: 220px;
        color: #007aff;
        font-size: 14px;
    }

    .switchWrapper{
        font-size: 18px;
        margin: 5px 0 0 6px,;
        color: rgba(0, 0, 0, 0.63);
    }

    .content-wrapper {
        padding-bottom: 20px;
    }
    
    .settings-section {
        padding-top: 34px;
        padding-left: 27px;
        padding-bottom: 14px;
        display: flex;
        .settings-violation {
            box-shadow: none;
            min-width: 270px;
        }

        .settings-action {
            box-shadow: none;
            min-width: 150px;
            text-align: center;
        }

        .settings-importance{
            box-shadow: none;
            min-width: 200px;
            text-align: center;
        }

        .settings-header {
            opacity: 0.63;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.5px;
            color: #000000;
        }

        .sub-header {
            padding-left: -2px;
            min-height:50px;
        }
    }

    .settings-subheader {
        padding-top: 12px;
        padding-left: 17px;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        color: #000000;
    }

    .settings-data-container {
        padding-top: 20px
    }

    .settings-data {
        padding-top: 16px;
        padding-left: 27px;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        color: #000000;
        display:flex;
        align-items: baseline;
        min-height: 58.3px;

        .settings-violation {
            box-shadow: none;
            min-width: 270px;
        }

        .settings-action {
            box-shadow: none;
            min-width: 150px;
            text-align: center;
        }

        .settings-importance{
            box-shadow: none;
            min-width: 200px;
            text-align: center;
        }

        .settings{
            opacity: 0.63;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.5px;
            color: #000000;
        }

        .sub-header {
            padding-left: -2px;
            min-height:50px;
        }

        .weightage-slider-outer{
            padding: 0px 24px;
            .weightage-slider-base {
                min-height:36px;
                top: 1px;
                border-bottom: solid 2px rgba(0, 0, 0, 0.26);
                position: relative;
            }
            .weightage-dividers{
                .left-text,.center-text,.right-text{
                    position: absolute;
                    width: 33.33%;
                    transform: translateX(-50%);
                    text-align: center;
                    left: 1px;
                    top: 5px;
                    height:21px;
                    font-size: 10px;
                    color: rgba(0, 0, 0, 0.7);
                    &:after{
                        background: rgba(0, 0, 0, 0.26);
                        height: 16px;
                        width: 1px;
                        position: absolute;
                        transform: translateX(-50%);
                        left: 50%;
                        content: '';
                        bottom: -10px;
                    }
                }
                .center-text{
                    left: 50%;
                }
                .right-text{
                    left: 100%;
                }
            }
        }
    }

    .settings-top-padding {
        padding-top: 11px
    }

    .save-btn-wrapper {
        padding-left: 4%;
    }

    .center-align {
        text-align: center;
    }
}