@import "../../../index.module";

.step-container {
  width: 98%;
  height: 760px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: 15px 15px 15px 15px;
}

.step-header {
  width: 100%;
  position: relative;
  padding: 17px 0;
  background-color: #343e48;
  -webkit-box-shadow: 0 2px 4px 0 rgba(189, 144, 144, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  button {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 25px;
    cursor: pointer;
  }

  i {
    width: 18px;
    height: 18px;
    margin: 0px 11px;
    display: inline-block;
    background-position: 0;
    &.back-icon {
      background: url("../../../assets/icons/back.png") no-repeat;
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
}
.button-container {
  button {
    margin: 10px;
  }
}
.button-container {
  display: inline-block;
  float: right;
}
.step-rightside {
  height: 380px;
  font-size: 15px;
  padding-top: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  overflow-y: auto;
  overflow-x: hidden;
}
.step-req {
  margin-left: 20px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
}
.step-content {
  width: 96%;
  height: 520px;
  margin: 20px;
  display: block;
}
.divider-shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}
.vertical-divider {
  height: 463px;
  padding: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.5);
}

.col-md-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 20%;
  float: left;
}

.alerttype {
  font-weight: bold;
  padding: 5px !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .col-md-5ths {
    width: 100%;
    float: left;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .col-md-5ths {
    width: 50%;
    float: left;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .col-md-5ths {
    width: 50%;
    float: left;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .col-md-5ths {
      width: 25%;
      float: left;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .col-md-5ths {
      width: 100%;
      float: left;
    }
  }
}
// to remove box outline of back in alterstepper
.no-focus:focus {
  outline: none;
}
// opacity of text  in alterstepper
.text-op {
  opacity: 0.35;
  filter: Alpha(opacity=35);
}
// paddding between text in  in altestepper
.div-pad {
  padding-top: 25px;
}
// width of text box in alterstepper
.text-wid {
  width: 328px;
}

// left paddding to textfield in  in fuel alert altestepper
.left-pad {
  padding-left: 38px;
}
.in-pad {
  padding-top: 28px;
}
.inn-pad {
  padding-top: 10px;
}
.fuel-alertradio label {
  flex-direction: unset;
}
// for spacing b/w chips
.chip-spacing{
  margin: 10px;
}

// Alert-Recipient styles
.searchInputField{
  width: 27vw;
}
.add-button-div{
  margin-left: 62px;
}
.add-button{
  margin-left: 62px;
  background-color: #007aff !important;
  border-radius: 2px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12) !important;
  color: white !important;
}
.comments-field{
  width: 33vw;
}
.Recipient-chip-container{
  height: 318px;
  overflow-x: hidden;
  overflow-y: auto;
}

// width of droppdown in alertScheduler
.droppdown-wid {
  width: 200px;
}
// height for time dropdown in Alert Schedule
// TODO: Find a better way to handle this.
div[role="document"] {
  ul[role="listbox"] {
    max-height: 250px;
  }
}
.select-padding{
  margin-left: 34px;
}
.selectb-padding{
  margin-left: 63px;
  margin-top: 10px;
}
.in-padd {
  padding-top: 10px;
}
// for spacing b/w chips
.chip-spacing{
  margin: 10px;
}
.chip-container{
  height: 216px;
  overflow-y: auto;
  overflow-x: hidden;
}
.schedule-container{
  margin-top:20px;
}

// Alert-Recipient styles
.searchInputField{
  width: 27vw;
}
.addButtonDiv{
  margin-left: 62px;
}
.commentsField{
  width: 33vw;
}
.unit-Name{
  display: inline-block ;
  padding-top: 20px ;
}
.unit-Name-style{
  display: inline-block ;
}
// Alert-landmark styles
.select-Input-Field{
  width: 328px;
}
.load-pos{
  display: inline-block;
}
.landmark-selection{
  min-width: 85% !important;
  max-width: 360px;
}
// width of text box in alterstepper
.text-widfuel {
  width: 300px;
}

.email-container {
  width: 100%;
}

.module-header-name {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: normal;
  color: #000000;
  margin-top: 30px;
  position: relative;
  float: left;
  margin-bottom: 20px;
}

.overlay-image {
  position: relative;
  width: 100%;
  margin-top:-48px;
}

.overlay-image .image {
  display: block;
  width: 100%;
  height: auto;
  .text {
    color: #fff;
    font-size: 14px;
    line-height: 1.43;
    text-shadow: 2px 2px 2px #000;
    text-align: left;
    position: relative;
    top: 50%;
    left: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);
    width: 84%;
  }
  .hover {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
  }
  .normal {
    transition: 0.5s ease;
  }
  .hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.overlay-image:hover .hover {
  opacity: 1;

  .normal {
    opacity: 0;
  }
}

.lookimg-container {
  width: 172px;
  height: 248px;
  margin-right: 10px;
  border-radius: 2px !important;
  box-shadow: 0px 3px 5px gray !important;
}

.look-name {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.43;
  color: #000000;
  text-align: center;
}

.look-action {
  width: 172px;
  height: 68px;
  border-radius: 2px;
  background-color: #ffffff;
}

.clear-fix {
  clear:both;
}

.module-viz-radio{
  z-index: 1000;
  width:48px;
  height:48px;
}

.module-select {
  width:225px;
}

.module-text {
  width:450px;
}

.criteria-block {
  float: left;
  margin-left: 20px;
}
