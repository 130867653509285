@import "../../index.module";
.container {
    .asset-header {
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.7px;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .sub-header {
        opacity: 0.73;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #ffffff;
    }

    .sub-header-2 {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #ffffff;
    }

    .sub-header-container {
        white-space: nowrap;
    }

    .icon-holder {
        color: #ffffff;
        white-space: nowrap;
        text-align: right;
        float: right;
    }

    .left-arrow-icon {
        color: #ffffff;
        white-space: nowrap;
        margin-right: 10px;
        height: 16px;
        width: 16px;
        top: 2px;
        position: relative;
        cursor: pointer;
    }

  .right-arrow-icon {
        color: #ffffff;
        white-space: nowrap;
        margin-left: 10px;
        height: 16px;
        width: 16px;
        top: 2px;
        position: relative;
        cursor: pointer;
    }

  .trip-selector {
      margin-bottom: 10px;
      margin-left: 9px;
  }

    .address {
        opacity: 0.87;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #ffffff;
        padding-bottom: 0px !important;
    }

    .card {
        width: 100%;
        min-height: 92px;
        background: #343e48;
        position: relative;
        box-shadow: none;
        border-radius: 0;
        padding: 0px 16px 0px 16px;
    }

    .trip-head-wrapper {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 16px;
    }

    .trip-detail-head-wrapper {
      margin-top: 10px;
      margin-left: 16px;
    }

    .back-button {
        border: none;
        padding:0px;
        text-decoration: none;
        // margin-top: 0px;
        position: relative;
        cursor: pointer;
        height: 22px;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        // text-align: left;
        color: #ffffff;
        outline: none;
        background-color: transparent;
        text-align: center;
        i {
          width: 18px;
          height: 18px;
          display: inline-block;
          background-position: 0;
          &.back-icon {
            background: url("../../assets/icons/back.png") no-repeat;
          }
          &.user-icon {
            background: url("../../assets/icons/account.svg") no-repeat;
          }
        }
      }
    .no-trips {
        text-align: center;
        margin: 50px;
    }

    .waypoint-icon {
        background: url("../../assets/icons/trips/waypoint-icon.svg") no-repeat;
        display: inline-block;
        height: 50px;
        width: 50px;
    }

    .tripTitle {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
    }

    .tripSubTitle {
        font-family: "Roboto", sans-serif ;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.54);
    }

    .unauthTitle {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #ffffff;
    }

    .unauthSubTitle {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.74);
    }

    .unauthTiny {
        font-family: "Roboto", sans-serif;
        font-size: 10px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.1px;
        text-align: right;
        color: #000000;
    }

    .back-icon {
        background: url("../../assets/icons/trips/back-icon.svg") no-repeat;
        display: block;
        height: 18px;
        width: 12px;
        float: right;
    }

    .unauthorized-icon {
        background: url("../../assets/icons/trips/unauthorized-icon.svg") no-repeat;
        display: inline-block;
        height: 50px;
        width: 50px;
    }

    .stopped-icon {
        background: url("../../assets/icons/trips/stopped-icon.svg") no-repeat;
        display: inline-block;
        height: 50px;
        width: 50px;
    }

    .notification-icon {
        background: url("../../assets/icons/trips/notifications.svg") no-repeat;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-top: 2px;
    }

    .trips-loader {
        text-align: center;
        margin: 50px;
    }

    .trip-name {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
    }

    .trip-distance {
        height: 24px;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.54);
    }

    .alerts-content{
        width: 80%;
        height: auto;
        margin-top: -10px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.63);
        white-space: normal;
        padding-left: 10px;
        float:left;
        padding-bottom:12px;
    }

    .trip-card-wrapper{
        text-decoration: none !important;
    }

    .date-container {
        white-space: nowrap;
    }

    .date-picker {
        float: right;
    }

    .date-picker > div {
        color: #ffffff;
        opacity: 0.83;
    }
    
    .date-picker > svg {
        color: #ffffff;
        opacity: 0.83;
    }
    .typography {
        line-height: 1.334;
    }
}
