.recording_container {
    display: table;
    height: 100%;
    margin: 15px;
    width: 98%;

    .heading_container {
        padding: 17px 21px;

        .page_heading {
            color: rgba(0, 0, 0, 0.87);
            font-size: 20px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 500;
            line-height: 1.6;
            letter-spacing: 0.12px;
            margin: 0;
        }

        .page_subheading {
            opacity: 0.63;
            color: #4a4a4a;
            font-size: 14px;
            width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin: 0;
        }
    }

    .filter_container {
        padding: 17px 50px;

        .recordingDatePicker {
            font-size: 14px;
            width: 200px;
        }

        .cameraDdl {
            font-size: 14px;
            margin: 18px 0 0 20px;
            width: 120px;
        }
    }

    .seekbar_container {
        padding: 17px 50px;

        .playbackBtnContainer {
            display: inline-block;
            margin-left: 15px;
            width: 18px;

            .playBtnContainer {
                margin-top: -18px;
                padding: 0;

                .playBtn {
                    background-color: #5F5F5F;
                    border-radius: 3px;
                    color: #ffffff;
                    height: 18px;
                    width: 18px;
                }

                .disabledBtn {
                    background-color: rgba(0, 0, 0, 0.26) !important;
                }
            }

            .downloadBtnContainer {
                margin-top: -10px;
                padding: 0;

                .downloadBtn {
                    color: #5F5F5F;
                    height: 25px;
                    margin-left: -3px;
                    transform: rotateZ(180deg);
                    width: 25px;
                }

                .disabledBtn {
                    color: rgba(0, 0, 0, 0.26) !important;
                }
            }
        }

        .qualityBtnContainer {
            display: block;
            margin-left: 397px;

            .videoQualityDdl {
                display: inline-block;
                font-size: 10px;
                width: 128px;
            }

            .infoBtn {
                color: #5F5F5F;
                display: inline-block;
                margin: 10px 0 0 14px;
                cursor: pointer;
            }

            .disabledBtn {
                color: rgba(0, 0, 0, 0.26) !important;
            }
        }
    }

    .video_player_container {
        padding: 17px 21px;

        .stream_details {
            margin-left: 130px;
            padding-top: 10px;
            display: block;
        }
    }
}

.triangle_at_top_right {
    position: relative;
}

.tooltip {
    max-width: 400px !important;

    > div > div {
        padding: 5px 0px;
    }
}

.tooltipPopper {
    max-width: 410px !important;
}

.triangle_at_top_right::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #8CC34A transparent transparent;
}

.downloadRecordingPopOver {
    border-radius: 2px;
    height: 415px;
    margin: 3px !important;
    width: 360px !important;

    .fieldset {
        width: 100%;
    }

    .cameraName {
        display: block;
        font-size: 16px;
        margin-top: -5px;
    }

    .selectedDate {
        color: #656465;
        display: block;
        font-size: 14px;
    }

    .timePicker {
        width: 100%;
    }

    .ddlContainer {
        width: 100%;
    }

    .recNotAvailableError {
        color: rgb(244, 67, 54);
        font-size: 11px;
        margin: 5px 12px;
    }

    .buttonContainer {
        float: right;

        .downloadAnchor {
            color: #007AFF;
            font-size: 14px;
            margin-right: 76px;
            text-decoration: none;

            span {
                margin-top: 9px;
                position: absolute;
            }
        }
    }
}

.inputLabel {
    left: -14px;
    top: 8px; 
}

.gridItem {
    padding: 12px !important;
}
