.csText {
    font-size: 13px,
}

.csEmail {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.63);
    display: inline;
}

%aTag {
    color: #007aff !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 13px !important;
}

.csEmailText {
    padding-left: 6px !important;
    text-decoration: none;
    color: #007aff;
    @extend %aTag;
    &:active {
        @extend %aTag;
        line-height: inherit !important;
        letter-spacing: -0.5px !important;
    }
}

.csNumber {
    font-size: 13px;
    padding-left: 4px;
    color: #000000;
    vertical-align: bottom !important;
}

.cs {
    padding: 10px 0;
}

.borderPadding {
    padding-top: 4px;
}

.csPadding {
    padding-bottom: 5px;
}

/* Target IE11 and set font-weight to 400 */
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    %aTag {
    font-weight: 300 !important;
    text-shadow: 0.1px 0 0 !important;
  }
  .csEmail {
    color: #000000;
    opacity: 0.63;
  }
}

.csEmailBox {
    margin: 0;
    display: revert;
    overflow: hidden;
    width: calc(83%);
    text-overflow: ellipsis;
    float: right;
}

.csEmailTextPadding0 {
    padding-left: 0px !important;
}

.csEmailWidth {
    width: calc(85%) !important;
    a {
        padding-left: 0 !important;
    }
}
