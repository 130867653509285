@import '../../index.module';

.screen {
  margin: 20px;
}

.menu {
  width: 955px;
  border-style: solid;
  border-color: gray;
  border-width: 2px;
  margin-bottom: 10px;
  padding: 20px;
}

.button {
  display: inline-flex;
  vertical-align: middle;
}

@media print {
  .menu {
    display: none !important;
  }

  .map {
    page-break-after: always;
  }
}
