@import "../../../index.module";

.asset_card_holder {
    background-color: #9B9B9B;
    padding-left: 8px;
    margin-bottom: 2px;
    max-height: 91px;
    position: relative;

    .assetCard {
        box-shadow: 63px 3px 3px -2px rgba(0, 0, 0, 0.2);
        border-radius: 0px;
        cursor: pointer;
    
        .assetCardHeader {
            padding: 14px 8px 14px 14px;
            
            .tooltip {
                position: absolute;
                display: inline-block;
                top: 15px;
                z-index: 2;
                left: 46px;
                font-size: 18px;

                + .tooltipText {
                    visibility: hidden;
                    display: inline-block;
                    color: #000;
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.36);
                    background-color: #ffffff;
                    text-align: center;
                    border-radius: 5px;
                    padding: 5px 10px;
                    
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 3;
                    top: 40%;
                    left: 18%;
                    margin-left: -60px;
                    font-size: 12.5px;
                    line-height: 1.52;
                    letter-spacing: 0.09px;
                }

                &:hover {
                    ~ .tooltipText{
                        visibility: visible;
                    }
                }
            }

            .cargo_status {
                bottom: 11px;
                position: absolute;
                font-size: 7px;
                border-radius: 4px;
                background-color: rgb(0, 122, 255);
                color: rgba(255, 255, 255, 0.87);
                width: 40px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                background-color: '#007aff',
            }
        }
    }

    .selected_card {
        background-color: #ADDCFF;
    }

    .assetCard:hover {
        background-color: #d8d8d8;
    }

    .asset_name_btn {
        outline: none;
        padding: 0;

        .asset_name {
            color: rgba(0, 0, 0, 0.87);
            font-size: 16px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.15px;
            text-align: left;
            text-decoration: underline;
            max-width: 184px;
        }
    }

    .asset_speed_dir_container {
        margin-right: 10px;
        position: absolute;
        right: 0;

        .speed_dir_txt {
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
            letter-spacing: normal;
            margin: 0;
        }
        .distance_txt {
            color: rgba(0, 0, 0, 0.63);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.13px;
            margin: 0;
        }

        .asset_dir_container {
            padding-top: 2px;

            i {
                float: right;
                width: 14px;
                height: 14px;
                margin-left: 5px;
                margin-top: 2px;
                background: url("../../../assets/icons/arrow.svg") no-repeat;

                &.NE_dir {
                    @include icon_rotate_mixin(45);
                }

                &.E_dir {
                    @include icon_rotate_mixin(90);
                }

                &.SE_dir {
                    @include icon_rotate_mixin(135);
                }

                &.S_dir {
                    @include icon_rotate_mixin(180);
                }

                &.SW_dir {
                    @include icon_rotate_mixin(225);
                }

                &.W_dir {
                    @include icon_rotate_mixin(270);
                }

                &.NW_dir {
                    @include icon_rotate_mixin(315);
                }
            }
        }
    }

    .card_subtitle {
        font-size: 14px;
        line-height: 21px;
        min-height: 9px;
        width: 182px;

        .asset_landmark {
            color: rgba(0, 0, 0, 0.63);
            display: block;
            outline: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .last_reporting_text {
            font-size: 12px;
            line-height: 19px;
            width: 198px;
            letter-spacing: 0.09;
        }
    }
}

.selected_card_holder {
    background-color: #007AFF;
}

.history {
    width: 18px;
    height: 18px;
    object-fit: contain;
    background: url("../../../assets/icons/assets/history.png") no-repeat;
    display: table-cell;
    vertical-align: middle;
    background-position: 0;
}

.unit {
    display: flex;
    justify-content: flex-end;
    height: 24px;
}

.button {
    display: flex;
    justify-content: flex-end;
}

