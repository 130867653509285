.card {
    width: 100%;
    -webkit-backdrop-filter: blur(2.3px);
    backdrop-filter: blur(2.3px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 70% 50%;
    background-color: #343e48 !important;
    position: relative;
    box-shadow: none;
    border-radius: 0 !important;

    .card-content {
        padding-top: 12px;
        padding-bottom: 29px;
        padding-left: 15px;
        color: #ffffff;

        .kml-text {
            font-size: 24px;
            font-weight: 600;
            display: inline-block;
        }

        .kml-count {
            opacity: 0.73;
            font-size: 16px;
            letter-spacing: -0.43px;
            color: #ffffff;
        }
    }
    .add-kml {
        padding-bottom: 0px !important;
        padding-top: 0;
        padding-left: 15px;
        padding-right: 17px;
    }
}
.no-kml {
    margin: 80px 20px;
    text-align: center;
}

.detailLoader {
    margin: 50px;
    text-align: center;
}

.back_button {
    flex: auto;
    border: none;
    padding-left: 2px;
    padding-top: 3px;
    text-decoration: none;
    margin-top: 0px;
    position: relative;
    cursor: pointer;
    height: 22px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: left;
    color: #ffffff;
    outline: none;
    background-color: transparent;

    i {
        width: 18px;
        height: 18px;
        display: inline-block;
        background-position: 0;

        &.back_icon {
            background: url("../../assets/icons/back.png") no-repeat;
        }
    }

    span {
        display: inline-block;
        top: -3px;
        position: relative;
    }
}
