.container{
  background: #f5f5f5;
  position: absolute;
  top: 0;
  right: 0;
}

.full-width {
  width: 100%;
}

.calc-width {
  width: calc(100% - 370px);
}