.recording_slots_container {
    display: inline-block !important;
    height: 43px;
    width: 526px;

    .slots_container {
        background-color: transparent;
        height: 43px;
        position: relative;
        z-index: 1;

        .slots {
            background-color: #E02020;
            position: absolute;
            width: 1px;
        }

        .thumb_tracker_line {
            background-color: #000000;
            font-size: 12px;
            height: 55px !important;
            position: absolute;
            top: -11px;
            width: 1px;

            span {
                display: inline-block;
                left: 3px;
                position: relative;
                top: -5px;
                width: 55px;
            }
        }

        .slots,
        .thumb_tracker_line {
            border: none;
            display: inline-block;
            height: 100%;
            margin: 0;
            padding: 0;
        }
    }

    .sliderBaseContainer {
        height: 43px;
        background-color: #f3f3f3;
        top: -43px;
        padding: 0;
        color: transparent;
        border-radius: 0;
        display: block !important;

        .trackBeforeDiv {
            width: 0px;
        }

        .trackAfterDiv {
            width: 0px;
        }

        .thumbContainerDiv {
            top: 51px;
        }

        .thumbBtn {
            background-color: #ffffff;
            box-shadow: 0 2px 5px 0 #000000;
            height: 15px;
            width: 15px;
            top: 52px;

            &:hover {
                box-shadow: 0px 0px 0px 9px rgba(0, 0, 0, 0.16);
            }
        }

        .activatedThumbBtn {
            .thumbBtn {
                box-shadow: 0px 0px 0px 18px rgba(0, 0, 0, 0.16);
            }
        }
    }
}
