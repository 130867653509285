@import "../../index.module";

.user-account {
  width: 100%;
  height: 100%;
  display: flex;
  .user-account-nav {
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        display: block;
      }
    }

    nav {
      padding-top: 0px;
    }
    nav > div {
      border-radius: 4px;
      width: 224px;
      padding: 7px 6px;
    }

    span {
      opacity: 0.67;
      padding-right: 16px;
    }

    .list-item-text > span {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: rgba(0, 0, 0, 0.87);
      opacity: 0.67;
    }

    .list-Item-Icon {
      min-width: 50px;
    }
  }

  .user-accounts-details {
    width: 100%;
    min-height: 600px;
    margin-left: 10px;
    height: calc(90vh - 100px);
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    .settings-heading {
      height: 34px;
      width: 100%;
      padding: 20px;
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.8px;
      color: #4a4a4a;
      margin: 0;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .settings-body {
    width: 420px;
    margin: 0 auto;
    display: block;
    padding-top: 80px;
    .settings-bottom-container {
      width: 280px;
      margin: 0 auto;
    }
    .settings-input {
      padding: 35px 0 0 0;
      div {
        input, select {
          width: 95%;
          height: 32px;
          border-radius: 4px;
          outline: none;
          padding: 0 10px;
          margin: 10px 0 10px 0;
          background-color: rgba(216, 216, 216, 0.23);
          border: none;
          font-size: 16px;
          letter-spacing: -0.5px;
          color: #343e48;
          position: relative;
        }
        > span {
          height: 22px;
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.5px;
          text-align: left;
          color: #343e48;

        }
      }
    }

    hr {
      border-width: 1px;
      margin-top: 5PX;
      height: 0px;
    }
  }

  .settings-footer {
    padding: 30px 0 0 0;
    button {
      padding: 0;
      background-color: transparent;
      height: 32px;
      border: none;
      font-size: 24px;
      width: 136px;
      border-radius: 3px;
      &.button-save-changes {
        cursor: pointer;
        font-size: 24px;
        border-radius: 4px;
        background-color: #007aff;
        letter-spacing: -0.4px;
        color: $white;
        margin-left: 8px;
        height: 32px;
        &:hover {
          background-color: #0055B2;
        }
        .save-button-text {
          text-transform: capitalize;
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.5px;
          text-align: left;
          color: $white;
        }
      }
      &.button-cancel {
        color: #979797;
        border-radius: 4px;
        border: solid 1px #979797;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        text-align: left;
        text-transform: capitalize;
        height: 32px;;
        .cancel-button-text {
          text-transform: capitalize;
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.5px;
          text-align: left;
        }
      }
    }
  }

  .account-icon {
    background: url("../../assets/icons/settings/userAccountSmall.svg") no-repeat;
    height: 18px;
    width: 18px;
    margin: 0;
  }
  .app-settings {
    background: url("../../assets/icons/settings/settings.svg") no-repeat;
    height: 18px;
    width: 18px;
    margin: 0;
  }
  .password {
    background: url("../../assets/icons/settings/password.svg") no-repeat;
    height: 18px;
    width: 18px;
    margin: 0;
  }
  .active {
    background-color: #d8d8d8;
    span {
      font-weight: bold !important;
      color: #000000 !important;
      opacity: 1 !important;
    }
  }
}

.settings-input-select {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  outline: none;
  padding: 0px 10px;
  margin: 10px 0 10px 0;
  background-color: rgba(216, 216, 216, 0.23);
  border: none;
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #343e48;
}

.app-settings-select {
  min-width: 250px  !important;
} 

.display-msg {
  padding-top: 12px;
  padding-bottom: 12px;
  opacity: 0.67;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.5px;
  text-align: left;
  color: #000000;
  float: left;
  position: relative;
  width: 500px;
  span {
    margin-bottom: 0;
    vertical-align: top;
  }
  .display-icon {
    background: url("../../assets/warning-icon.svg") no-repeat;
    width: 20px;
    height: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: inline-block;
    margin-right: 10px;

  }
}

.display-msg-none {
  color: #000000 !important;
}

.display-msg-success {
  color: green !important;
}

.display-msg-error {
  color: red !important;
}

.menu-item {
  background: #ffffff !important;
}

.menu-item:hover {
  background: #ffffff !important;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  border: solid 2px #d4d4d4;
}
