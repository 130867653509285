.cluster-screen {
    z-index: 99;
    top: 0px;
    width: 387px;
}

.full-width {
    width: 100%;
}

.calc-width {
    width: calc(100% - 330px);
}

.mapWrapper {
    width: 100%;
}
