.home-icon {
  background: url("../../assets/icons/side_menu/home.svg") no-repeat;
  width: 18px;
  height: 17px;
  border-radius: 0;
}

.dashboard-icon {
  background: url("../../assets/icons/side_menu/dashboard.svg") no-repeat;
  width: 18px;
  height: 17px;
  border-radius: 0;
}

.breadcrumb-icon {
  background: url("../../assets/icons/side_menu/breadcrumb.svg") no-repeat;
  width: 18px;
  height: 18px;
  border-radius: 0;
}

.reports-icon {
  background: url("../../assets/icons/side_menu/signal.svg") no-repeat;
  width: 15px;
  height: 18px;
  border-radius: 0;
}

.settings-icon {
  background: url("../../assets/icons/side_menu/settings.svg") no-repeat;
  width: 18px;
  height: 18px;
  border-radius: 0;
}

.maintenance-icon {
  background: url("../../assets/icons/side_menu/maintenance.svg") no-repeat;
  width: 18px;
  height: 18px;
  border-radius: 0;
}

.warning-icon {
  background: url("../../assets/icons/side_menu/warning.svg") no-repeat;
  width: 18px;
  height: 15px;
  border-radius: 0;
  background-color: #4a4a4a;
}

.wave-icon {
  background: url("../../assets/icons/side_menu/map.svg") no-repeat;
  width: 18px;
  height: 17px;
  border-radius: 0;
  background-color: #4a4a4a;
}

.menu-text {
  font-size: 14px;
  flex: 1 1 auto;
  position: relative;
}

.menu-count {
  position: absolute !important;
  top: -3px;
  right: 0;
  background-color: #007aff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.menu-count>span {
  font-size: 11px;
  position: absolute !important;
  font-weight: bold;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.zero-padding {
  padding: 0 !important;
  cursor: pointer;

  &:hover {
    background-color: #d8d8d8;
    font-weight: bold;
    border-radius: 5px;
    width: 100% !important;
  }
}

.active-menu {
  background-color: #d8d8d8;
  font-weight: bold;
  border-radius: 5px;
  width: 100% !important;
}

.active-menu-closed {
  background-color: #d8d8d8;
  border-radius: 5px;
  width: 100% !important;
}

.custom-btn {
  border-radius: 5px !important;
  padding: 10px !important;

  & svg {
    font-size: 22px;
    position: relative;
    top: -3px;
    right: 2px;
    margin-top: 3px;
    margin-bottom: -7px;
    margin-left: 3px;
  }
}

.home-align {
  position: relative;
  top: -3px;
  right: 2px;
  font-size: 22px;
  margin-top: 3px;
  margin-bottom: -7px;
  margin-left: 3px;
}

.default-align {
  margin: 16px;
  opacity: 0.67;
  margin-left: 19px;
}

.disable-clicks {
  pointer-events: none;
}

.powered_by_logo_container{
  display: flex;
}

.powered_by_logo {
  width: 45px;
  height: 24px;
}

.SideMenuDrawer-drawerPaperClose-9 {
  width: 60px !important;
}
