$white: #fff;
$gunsmoke: #858585;
$lite_grey: rgba(216, 216, 216, 0.23);
$grey: #d8d8d8;
$celeste: #ccc;
$tuna: #343e48;
$dark_red: #b3282d;
$blue: blue;
$green: #4a4a4a;
$light_grey: #e6e6e6;
$default_search: #737D85;
$oval : #03a9f4;


$sansSerif: sans-serif;
$border_line : #979797;
$icon_txt : #007aff;
$theme_primary_color: #007aff;
$roboto: Roboto;
$themeFont: 'Roboto';
$themeFontBold: 'Roboto Bold';
$themeFontMediumBold: 'Roboto Medium';

%extend_min_height {
  min-height: 607px;
}

%container-block {
  display: table;
  list-style: none;
  background: $grey;
  width: 461px;
  height: 57px;
  border-radius: 0;
  margin-left: 0 !important;
  box-sizing: border-box;
}

%container-block-li {

  width: 485px;
  margin-top: 4px;
  padding: 8px 16px;
  border-radius: 2px;
  background: $white;
  box-sizing: border-box;
  height: 57px;
}
%container-block-div {
  display: inline-block;
  vertical-align: middle;
}
%container-block-oval {
  width: 44px;
  height: 44px;
  background-color: $oval;
  border-radius: 50%;
  margin-top: -2px;
}

body {
  margin: 0;
  padding: 0;
  font-family: $themeFont, $sansSerif;
  overflow: auto;
  min-width: 768px;
}

input, textarea, button {
  font-family: $themeFont, $sansSerif;
}

.map-live-traffic, .gm-view-button {
  &:hover {
    background-color: rgb(235, 235, 235) !important;
  };
}

div[role="button"] {
  cursor: pointer;
  outline: none;
  border: none;
}

// this is used to rotate icon based on provided degree for different browswers
@mixin icon_rotate_mixin($degreeOfRotation) {
  -ms-transform: rotate(#{$degreeOfRotation}deg);
  -webkit-transform: rotate(#{$degreeOfRotation}deg);
  transform: rotate(#{$degreeOfRotation}deg);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
