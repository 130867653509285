@import "../../index.module";

.container {
    background: #ffff;
}

.mapWrapper {
    width: 100%;
}

.list-item {
    padding: 17.5px 17.5px 0 17.5px;

    .asset-avatar {
        background-color: #2cc5b4;

        i {
            width: 24px;
            height: 17px;
            object-fit: contain;
            position: relative;
            vertical-align: middle;
            background-position: 0;
            display: block;
            margin-left: 3px;
            margin-top: 2px;

            &.truck {
                background: url("../../assets/icons/assets/truck.svg") no-repeat;
            }

            &.equipment {
                margin: auto;
                background: url("../../assets/icons/assets/equipment.svg") no-repeat;
            }

            &.trailer {
                background: url("../../assets/icons/assets/trailer.svg") no-repeat;
            }

            &.vehicle {
                background: url("../../assets/icons/assets/vehicle.svg") no-repeat;
            }
        }
    }

    .tooltip {
        position: absolute;
        display: inline-block;
        top: 12px;
        z-index: 2;
        left: 38px;
        font-size: 18px;

        + .tooltipText {
            visibility: hidden;
            display: inline-block;
            color: #000;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.36);
            background-color: #ffffff;
            text-align: center;
            border-radius: 5px;
            padding: 5px 10px;
            
            /* Position the tooltip */
            position: absolute;
            z-index: 3;
            top: 40%;
            left: 18%;
            margin-left: -60px;
            font-size: 12.5px;
            line-height: 1.52;
            letter-spacing: 0.09px;
        }

        &:hover {
            ~ .tooltipText{
                visibility: visible;
            }
        }
      }

    .asset-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 19px;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: rgba(0, 0, 0, 0.87);
    }

    .movement-and-last-location {
        font-size: 14px;
        line-height: 1.36;
        letter-spacing: 0.1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .last_reporting_text {
            font-size: 12px;
            line-height: 19px;
            letter-spacing: 0.09;
            color: rgba(0, 0, 0, 0.5);
        }

        .Moving {
            color: #689f38;
        }

        .Stopped {
            color: #d32f2f;
        }

        .Idle {
            color: #f57c00;
        }

        .asset-status-null {
            color: rgba(0, 0, 0, 0.5);
        }

        .last-location {
            color: rgba(0, 0, 0, 0.5);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .asset-details-right-side {
        color: rgba(0, 0, 0, 0.5);
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
    }

    .asset-direction-and-direction-icon {
        padding-top: 2px;
    }

    i {
        float: right;
        width: 14px;
        height: 14px;
        object-fit: contain;
        position: relative;
        display: block;
        margin-left: 5px;
        margin-top: 2px;
        background: url("../../assets/icons/arrow.svg") no-repeat;
        /* default pointing N */

        &.NE_dir {
            @include icon_rotate_mixin(45);
        }

        &.E_dir {
            @include icon_rotate_mixin(90);
        }

        &.SE_dir {
            @include icon_rotate_mixin(135);
        }

        &.S_dir {
            @include icon_rotate_mixin(180);
        }

        &.SW_dir {
            @include icon_rotate_mixin(225);
        }

        &.W_dir {
            @include icon_rotate_mixin(270);
        }

        &.NW_dir {
            @include icon_rotate_mixin(315);
        }
    }
}

.list-item {
    padding: 15px !important;

    .list-item-icon {
        background: url("../../assets/icons/landmark_group/landmark_icon.svg") no-repeat;
        width: 44px;
        height: 44px;
        margin: 0;
    }
}

.list-border {
    position: absolute;
    width: 79%;
    height: 2px;
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.5);
    left: 72px;
}
