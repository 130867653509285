.mapCloseButton {
  position: absolute;
  right: 0px;
  top: 0px;
  border: 1px solid !important;
  background-color: #fff;
  width: 15px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}
.map {
   width: 400px;
   height: 384px;
}