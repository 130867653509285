@import "../../../index.module";

.container {
  .event_list_header_card {
    width: 100%;
    min-height: 90px;
    background-color: #343e48;
    box-shadow: none;
    border-radius: 0;
    padding: 7px 15px 15px 15px;
  }

  .dashcam_status_container {
    display: flex;
    position: relative;
    top: -7px;
    
    .dashcam_status_icon {
      margin-right: -3px;
      position: relative;
      top: -3px;
    }
  }

  .event_list_header_title {
    color: #ffffff;
    font-family: $themeFontMediumBold;
    font-size: 24px;
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event_filter_selected {
    width: 180px !important;
  }

  .event_list_filter_btn_container {
    display: inline-flex;
    position: inherit;
    right: 20px;
    cursor: pointer;
    
    svg {
      height: 20px;
      margin-left: 20px;
      position: relative;
    }
  }

  .event_list_subheader_title {
    color: #ffffff;
    font-family: inherit;
    font-size: 16px;
    letter-spacing: -0.4px;
    opacity: 0.73;
  }

  .back_button {
    float: left;
    border: none;
    padding: 0;
    text-decoration: none;
    margin-top: 0;
    position: relative;
    cursor: pointer;
    height: 22px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: left;
    color: #ffffff;
    outline: none;
    width: 100%;
    background-color: transparent;

    i {
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: 0;

      &.back_icon {
        background: url("../../../assets/icons/back.png") no-repeat;
      }
    }

    span {
      display: inline-block;
      top: -3px;
      position: relative;
    }
  }

  .date_container {
    white-space: nowrap;
  }

  .date_picker {
    color: #ffffff;
    font-family: inherit;
    font-size: 15px;
    letter-spacing: -0.4px;
    opacity: 0.95;
    left: -10px;
    
    div {
      div {
        color: #ffffff;
      }
    }    
  }

  .date_picker > div > svg {
    color: #ffffff;
    opacity: 0.83;
  }

  .error_message {
    color: #FF0000;
    font-family: inherit;
    font-size: 16px;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.labelSelected {
  font-size: 15px !important;
  color: #007aff !important;
  font-weight: 500 !important;
  line-height: 1.6 !important;
}

.label {
  font-size: 15px !important;
  line-height: 1.6 !important;
  color: var(--black-50) !important;
}

.event_download {
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
