.mainContainer {
  margin: 17px 16px !important;
  display: block !important;

  .toolbar {
    color: #4a4a4a;
    padding-left: 20px;

    .backButton {
      vertical-align: middle;
      padding-right: 10px;
      margin-left: -12px;
    }

    .editTitle {
      display: flex;
      justify-content: center;
      align-items: center;

      .iconButton {
        margin-right: -6px;
      }

      .icon {
        font-size: 20px;
      }

      .disabled {
        cursor: not-allowed;
        pointer-events: auto;
      }
    }

    .title {
      width: 200px;
      line-height: 1.2;
    }

    .titleWrap {
      padding-top: 16px;
      width: 200px;
      line-height: 1.2;

      .subHeader {
        opacity: 0.63;
        color: #4a4a4a;
        font-size: 14px;
        width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .textWrap {
    padding: 20px 29px 18px;
    overflow: auto;
    height: calc(100vh - 182px);

    .name {
      line-height: 0.75;
      color: rgba(0, 0, 0, 0.38);

    .fsize {
      font-size: 12px;
    }
    }
  }

  .text {
    padding-top: 22px;
  }

  .textField {
    width: 327px;
    overflow: hidden;
    white-space: nowrap;
  }

  .error {
    color: rgb(244, 67, 54);
  }

  .buttonContainer {
      padding: 22px 40px 20px;
      width: 350px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .button {
        width: 88px;
        height: 36px;
      }

      .cancelButton {
        margin-right: 5px;
        color: #8e8e8e;
      }
  }
}

.switchRoot {
  margin: 4px 0px 0px 28px;
}

.switchBase {
  height: 38px;
}

.select {
  color: #4a4a4a;
  font-size: 14px;
  width: 76px;
  margin-top: 6px;
}

.eventSelect {
  font-weight: 500;
  width: 110px;
  margin-top: 6px;
}

.gridItem {
  margin-top: 15px !important;
  color: #4a4a4a;
  font-size: 14px;
  line-height: normal,
}

.offlineDashcam {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  padding: 6px 14px;
  width: 250px;
  line-height: normal;
  font-size: 12px;
}

.offline {
  margin-left: 5%;
  padding: 8px 16px;
  width: 226px;
}

.refresh {
  width: 205px;
  margin-left: 7%;
  padding: 8px 2px;
}

.calibrationReady {
  display: flex;
  align-items: center;
  background-color: #eee;
  padding: 8px 16px;
  width: 420px;
  margin-left: 10%;
  line-height: normal;
  font-size: 12px;
}

.notCalibrationCompleted {
  display: flex;
  align-items: center;
  padding-top: 7px;
}

.notCalibrationCompletedSubHeader {
  font-size: 10px;
  color: #4a4a4a;
  line-height: normal;
}

.ReCalibrate {
  max-width: 32px !important;
  max-height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
}

.center {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.paper {
  height: calc(100vh - 80px);
}

.content {
  padding-bottom: 0px !important;
}

.gridContainer {
  height: 44px;
  margin-top: 10px;

  .settingCancelButton {
    margin-right: 16px;
    width: 75px;
  }

  .settingCalibrateButton {
    margin-right: 16px;
    width: 75px;
  }

  .settingCancelButton {
    width: 88px;
  }
}

.gridButton {
  padding-left: 35px;
}


.eventCategory {
  padding-left: 57px;
  padding-bottom: 30px;
  opacity: 0.87;
}

.eventCategoryTitle {
  opacity: 0.87;
}

.eventsName {
  padding-left: 18px;
  color: #4a4a4a;
  font-size: 14px;
}

.divider {
  border-radius: 2px;
}

.eventTailgating {
  width: 190px;
  margin-left: 20px;
}

.menuItemLabel {
  padding-left: 5px !important;
  padding-top: 0px !important;
}
