@import "../../index.module";

.navbar {
    border-radius: 0;
    position: relative;
    z-index: 1000;
    min-height: 90px;
    margin-bottom: 10px;
    background-color: $white;
    border-color: $light_grey;
    border-top: 4px solid #a44246;
}

.logo {
    width: 226px;
}

.search-dropdown-holder.focused {
    background: #d4d4d4 !important;
}

.search-dropdown-holder {
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 7px 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    * {
        box-sizing: border-box;
    }
    a {
        color: $blue;
        text-decoration: underline;
    }
    ul {
        margin: 0;
        padding: 0;
        .fat-list-item {
            min-height: 50px;
        }
    }
    .search-bar {
        width: 768px;
        height: 32px;
        border-radius: 2px;
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: -0.5px;
        color: $white;
        padding: 8px 0 8px 45px;
        outline: 0;
        border: 0;
        box-sizing: border-box;
        top: 0;
        background: url("../../assets/icons/default_search.svg") no-repeat scroll 15px 8px;
        margin: 0 auto;
        display: block;
        &:focus {
            color: $tuna;
            background: url("../../assets/icons/search.svg") no-repeat scroll 15px 8px;
            margin: 0 auto;
            display: block;
        }
    }

    .search-bar.lightGrey {
        background-color: #757C85;
    }
    .search-bar.lightBlue {
        background-color: #55a6ff;
    }
    .txt-active{
        color: $tuna;
        background: url("../../assets/icons/search.svg") no-repeat scroll 10px 8px;
        margin: 0 auto;
        display: block;
        background-color: #ffffff !important;
    }
    .company_logo{
        font-size: 16px;
        font-family: $themeFontMediumBold;
        float: left;
        margin-top: 5px;
        letter-spacing: -0.5;
        .first_txt{
            color:#b3282d;
        }
        .second_txt{
            color:#f5f5f5;
        }
    }
}

.search-dropdown-holder.grey {
    background-color: $tuna;
}
.search-dropdown-holder.blue {
    background-color: #007aff;
    display: flex;
}

.search-bar {
    max-width: 60%;
    input{
        float: left;
        padding-top: 0;
        padding-bottom: 0;
        width:100%;
        position: relative;
        z-index: 12;
    }
}

.search-icon{
    background: url("../../assets/icons/search.svg") no-repeat;
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.search-box-error-container{
    font-size: 18px;
    text-align: right;
    position: absolute;
    right: 0;
    z-index: 10;
    .error-msg{
        font-size: 14px;
        margin-right: 14px;
        height: 17px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #d91e18;
        display: inline-block;
        vertical-align: top;
    }
    .error-icon {
        background: url("../../assets/icons/box-important.svg") no-repeat;
        width: 14px;
        height: 14px;
        display: inline-block;
        margin-right: 9px;
        padding: 13px 8px 13px;
    }
}

.side-menu-icons {
    margin-top: -8px;
    float: left;
    margin-left: 7px;
    height: 47px;
}

.back-button-wrapper {
    color: #ffff;
    display: flex;
    margin-left: 15px
}

.back-button-text {
    margin-top: 5px;
    margin-left: 25px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #ffffff;
}
.back-button {
    outline: none;
    border: none;
}

.resellerLogo {
    max-width: 150px;
    max-height: 28px;
}

.hide {
    display: none;
}
