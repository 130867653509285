@import "../../../index.module";

.filters-container {
    position: relative;
    display: flex;
    height: 100vh;
    line-height: normal;
    letter-spacing: -0.7px;

    .filters-card-container {
        width: 370px;
        overflow: auto;
        padding: 24px;
    }

    .filters-keys-font {
        font-size: 1rem !important;
    }

    .filters-title-container {
        padding-bottom: 10px;

        .filters-title {
            height: 24px;
            font-size: 20px;
            display: inline-block;
            padding-left: 12px;
        }

        .back-arrow-black {
            width: 18px;
            height: 24px;
            object-fit: contain;
            background: url("../../../assets/icons/back-arrow-black.svg") no-repeat;
            display: inline-block;
            vertical-align: middle;
            border: none;
            cursor: pointer;
        }
    }

    .clear-button {
        padding-top: 3px;
        
        .button {
            cursor: pointer;
            background-color: #fff;
            border: none;
            outline: none;
            color: #007aff;
            font-size: 15px;
            font-weight: 500;
        }
    }

    .label-and-checkbox {
        width: 310px;

        .filter-checkbox-checked {
            color: #007aff;
        }
        .filter-checkbox-unchecked {
            color: #696969;
        }
    }
}
