.cluster-screen {
    z-index: 99;
    top: 0px;
    width: 387px;
}

.mapWrapper {
    width: 100%;
    -webkit-transition: width 300ms; /* Safari prior 6.1 */
    transition: width 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
}

.container {
    width: 100%;
}

.dispatch {
    height: calc(100vh - 85px);
}
