.main-outer{
    width: 254px;
    .bar-move-out {
        min-height:35px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.26);
        position: relative;

        span.bar-round {
            position: absolute;
            z-index: 2;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.26);
            bottom: -5px;
            border: solid 2px #FFF;
            left: 50%;
            transform: translateX(-50%);
            transition: .5s;
        }
        .bar-move{
            position: absolute;
            left: 0;
            height: 1px;
            bottom: -1px;
            background: #007aff;
            z-index: 1;
            width: 0;
            transition: .5s;
        }
    }
    .divider-3{
        .left-text,.center-text,.right-text{
            position: absolute;
            width: 33.3333%;
            transform: translateX(-50%);
            text-align: center;
            left: 0;
            top: 5px;
            height:21px;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.26);
            &:after{
                background: rgba(0, 0, 0, 0.26);
                height: 16px;
                width: 1px;
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                content: '';
                bottom: -10px;
            }
        }
        .center-text{
            left: 50%;
        }
        .right-text{
            left: 100%;
        }
    }
    &.bar-left,&.bar-mid,&.bar-right{
        .bar-move-out {
            span.bar-round {
                border: 0;
                width: 12px;
                height: 12px;
                background: #007aff;
            }
        }
    }
    &.bar-left{
        .bar-move-out {
            span.bar-round {
                left: 0;
                transform: translateX(0);
            }
            
        }
    }
    &.bar-mid{
        .bar-move-out {
            span.bar-round {
                left: 50%;
            }
            .bar-move{
                width: 50%;
            }
        }
    }
    &.bar-right{
        .bar-move-out {
            span.bar-round {
                left: 100%;
            }
            .bar-move{
                width: 100%;
            }
        }
    }
}