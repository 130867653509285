@import '../../../index.module.scss';

.event_card_holder {
  margin-bottom: 2px;
  position: relative;
  height: 75px;

  .eventCard {
    border-radius: 0;
    box-shadow: none;
    cursor: inherit;
    height: inherit;
    padding-left: 6px;

    .eventCardHeader {
      padding: 10px 8px 7px 10px;

      .tooltip {
        display: inline-block;
        font-size: 18px;
        left: 46px;
        position: absolute;
        top: 15px;
        z-index: 2;
      }
    }
  }

  .eventCard:hover {
    background-color: #d8d8d8;
  }

  .event_name_btn {
    outline: none;
    padding: 0;
    
    .event_name_container {
      left: 10px;
      width: 48px;
      height: 48px;
    }
    
    .event_icon {
      width: 48px;
      height: 48px;
    }

    .sub_header {
      font-size: 14px !important;
      color: rgba(0, 0, 0, 0.54) !important;
    }
    
    .event_name {
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      max-width: 210px;
    }

    .event_date {
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      max-width: 210px;
    }
    .event_video_icon {
      padding-left: 20px;
      cursor: pointer;
    }
  }
}

