@import "../../../index.module";
.link-button {
    padding: 0;
    border: none;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.8125rem;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    text-align: left;
    display: inline-block;
}

.dropdown {
    margin-left: 300px;
}

.cell-span {
    font-size: 0.795rem;
    min-width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 98%;
    text-align: left;
    display: inline-block;
    vertical-align: middle !important;
}

th, td {
    padding: 4px 20px 4px 0px !important;
    min-width: 90px;
    white-space: nowrap;
}

.checkboxWrapper {
    width: 50px;
}

th:first-child, td:first-child {
    min-width: 50px;
    margin: 0;
    padding: 4px 10px 4px 23px !important;
}

tr th div {
    i {
        cursor: grab;
        display: inline-flex;
        min-width: 50px;
        max-width: calc(100% - 110px);
        width: 100%;
    }
    i:active {
        cursor: grabbing;
    }
}

th:hover >div.resizable-column {
    border-right-style: double;
    border-right-color: #007aff;
    min-width: 100%;
}

th {
    line-height: 28px;
}

.resizing {
    min-width: 100%;
    border-right: 1px solid #007aff;
}

div[role="tooltip"] {
    z-index: 2 !important;
}

.clear-fix {
    clear:both;
}

.trip-list-row {
    height: calc(3.5vh) !important;
}
