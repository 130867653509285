@import "../../../index.module";
.container {
    .card {
        width: 100%;
        min-height: 140px;
        -webkit-backdrop-filter: blur(2.3px);
        backdrop-filter: blur(2.3px);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 70% 50%;
        background-color: #343e48;
        position: relative;
        box-shadow: none;
        border-radius: 0;
        font-family: $themeFont;
        .card-content {
            padding: 15px;
            .back-wrapper {
                float: left;
            }
            .back-button {
                float: left;
                padding-left: 2px;
                padding-top: 3px;
                height: 22px;
                font-size: 18px;
                color: #ffffff;
                outline: none;
            }
            .backlabel-header {
                color: #ffffff;
                font-size: 24px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 30px;
                letter-spacing: -0.7px;
                padding: 0px 0px 8px 6px;
                height: 28px;
            }
            .landmark-container {
                padding-top: 22px;
            }
            .landmark-icon {
                background-color: #FFFFFF;
            }
            .landmark-header {
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                letter-spacing: 0.2px;
            }
            .landmark-address {
                color: #ffffff;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1.36;
                letter-spacing: 0.1px;
            }
        }
    }
}
