@import "../../index.module";

.user-accounts-popup {
    position: absolute;
    right: 0px;
    top: 46px;
    width: 281px;
    background-color: #ffffff;
    z-index: 2;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding-bottom: 10px;
    .user-accounts-popup-header {
        padding: 8px 18px;
        .user-accounts-popup-header-icon {
            width: 30px;
            float: left;
            height: 30px;
            border-radius: 50%;
            background-color: #4a4a4a;
            margin: 12px 0 0;
            span {
                color: #ffffff;
                font-size: 18px;
                text-align: center;
                margin: 5px auto 0;
                width: 100%;
                float: left;
            }
        }
        .user-accounts-popup-header-name {
            float: left;
            width: 82%;
            margin: 5px 0 0;
            h1 {
                margin: 4px 0 0 10px !important;
                padding-left: 0px;
                float: left;
                font-size: 18px;
                letter-spacing: -0.7px;
                color: #4a4a4a;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            span {
                width: 100%;
                margin: 0 0 0 11px;
                display: block;
                font-size: 12px;
                color: #4a4a4a;
                letter-spacing: -0.5px;
                opacity: 0.7;
                word-break: break-word;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .user-accounts-popup-body {
        color: #4a4a4a;
        margin-bottom: 10px;
        margin-left: 20px;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                font-size: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                span {
                    cursor: pointer;
                    height: 30px;
                    font-size: 14px;
                }
                span:last-child {
                    cursor: pointer;
                    vertical-align: middle;
                }
            }
        }

    }
    .user-accounts-popup-footer {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        margin-left: 8px;
        button {
            padding: 8px 20px;
            background-color: transparent;
            height: 32px;
            border: none;
            font-size: 24px;
            border-radius: 3px;
            &.button-my-account {
                cursor: pointer;
                font-size: 24px;
                background-color: #007aff;
                letter-spacing: -0.6px;
                color: $white;
                height: 32px;
                border-radius: 4px;
                border: none;
                margin-right: 12px;
                margin-top: 3px;
                width: 38%;
                &:hover {
                    background-color: #0055B2;
                }
                .button-my-account-text {
                    text-transform: capitalize;
                    font-size: 18px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: -0.5px;
                    text-align: left;
                    color: $white;
                    margin-top: -4px;
                  }
                  .logout-button-text {
                    text-transform: capitalize;
                    font-size: 18px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: -0.5px;
                    text-align: left;
                    color: #979797;
                    margin-top: -4px;
                  }
              }
        }
        button:first-child {
            background-color: transparent;
            border: 1px solid #979797;
            color: #979797;
            width: 51%;
            &:hover {
                background-color: transparent;
            }
        }
    }

}

.width330 {
    width: 330px !important;
}
