@import "../../../index.module.scss";
.looklist-container {
  width: 96%;
  margin-left: 2px;
  margin-top: 16px;
}

.col-md-5ths {
  position: relative;
  min-height: 1px;
  padding: 8px;
}

.col-md-5ths {
  width: 20%;
  max-width: 182px;
  float: left;
}
.looktype {
  font-weight: bold;
  padding: 5px !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .col-md-5ths {
    width: 100%;
    float: left;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .col-md-5ths {
    width: 50%;
    float: left;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .col-md-5ths {
    width: 50%;
    float: left;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .col-md-5ths {
      width: 25%;
      float: left;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .col-md-5ths {
      width: 20%;
      float: left;
    }
  }
}

.overlay-image {
  position: relative;
  width: 100%;
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  .text {
    color: #fff;
    font-size: 14px;
    line-height: 1.43;
    text-shadow: 2px 2px 2px #000;
    text-align: left;
    position: relative;
    top: 50%;
    left: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);
    width: 84%;
  }
  .hover {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .normal {
    transition: 0.5s ease;
  }
}

.overlay-image:hover {
  .hover {
    opacity: 1;
  }
  .normal {
    opacity: 0;
  }
}

.lookimg-container {
  width: 172px;
  height: 248px;
  margin-left: 2px;
  border-radius: 2px !important;
  box-shadow: 0px 3px 5px gray !important;
}

.look-name {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.43;
  color: #000000;
  text-align: center;
}

.look-action {
  width: 172px;
  height: 68px;
  border-radius: 2px;
  background-color: #ffffff;
}

.look-catname {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: normal;
  color: #000000;
  margin-top: 40px;
  position: relative;
  float: left;
  width: 100%;
}
.back-button {
  vertical-align: middle;
  padding-right: 20px;
  margin-left: -12px;
}
