.container {
   .title {
        padding: 21px 0px 19px 23px;
        font-size: 20px;
        color: #000000;
        opacity: 0.87;
        text-transform: capitalize;
   }
   .fileDownloadLinkGrid {
    display: flex;
    justify-content: flex-end;
    padding-right: 17px;
    div {
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        color: #007aff;
        font-size: 14px;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        outline: none;
        white-space: nowrap;
    }
   }
   .backgroudColor {
        background-color: #f7f7f7;
        padding: 10px 0 10px 0;
        flex-flow: column;
        .reportCountText {
            font-size: 12px;
            opacity: 0.54;
            text-align: center;
            color: #000000;
        }
        .reportCount {
            text-align: center;
            color: rgba(0, 0, 0, 0.87);
            font-size: 28px;
            font-weight: 500;
            line-height: 0.57;
            padding: 10px 0 6px;
        }
   }
   .border {
        border-right: solid 1px #bcb1b1;
        height: 100%;
    }
   .failedItem {
        background-color: #343e48;
        display: flex;
        justify-content: space-between;
       .failedItemText {
            color: #ffffff;
            font-size: 18px;
            letter-spacing: -0.49;
            padding: 12px 23px;
       }
   }
}
