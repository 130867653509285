@import "../../../index.module";

.container {

    .routes {
        color: #ffffff;
        font-size: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .moving {
        color: rgb(126, 211, 33);
        font-size: 14px;
    }

    .idle {
        color: rgb(245, 166, 35);
        font-size: 14px;
    }

    .stopped {
        color: rgb(252, 2, 7);
        font-size: 14px;
    }

    .nonreporting {
        color: #fff;
        font-size: 14px;
    }

    /*----- TIMELINE MARKER -----*/
    .timelineMarker {
        position: absolute;
        top: 88px;
        bottom: 58px;
        left: 28px;
        width: 15px;
    }

    .timelineMarker:before {
        border: 1px solid transparent;
        border-radius: 100%;
        content: "";
        display: block;
        height: 10px;
        position: absolute;
        top: 7px;
        left: 0;
        width: 10px;
        z-index: 1;
        /* transition: background 0.3s ease-in-out, border 0.3s ease-in-out; */
    }

    .timelineMarker:after {
        content: "";
        width: 3px;
        display: block;
        position: absolute;
        top: 7px;
        bottom: -9px;
        left: 5px;
        border-left: 1.5px dashed #FFFFFF;

    }

    .timelineItem:last-child .timelineMarker:after {
        content: none;
    }

    .card {
        width: 100%;
        min-height: 200px;
        -webkit-backdrop-filter: blur(2.3px);
        backdrop-filter: blur(2.3px);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 70% 50%;
        background-color: #343e48;
        position: relative;
        box-shadow: none;
        border-radius: 0;
        font-family: "Roboto", sans-serif;

        .card-content {
            padding: 15px;

            .back-wrapper {
                float: left;
            }

            .back-button {
                float: left;
                padding-left: 2px;
                padding-top: 3px;
                height: 22px;
                font-size: 18px;
                color: #ffffff;
                outline: none;
            }

            .backlabel-header {
                color: #ffffff;
                font-size: 24px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 30px;
                letter-spacing: -0.7px;
                padding-bottom: 8px;
                height: 28px;
            }

            .asset-header {
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                letter-spacing: 0.2px;
            }

            .tooltip {
                position: absolute;
                display: inline-block;
                top: 10px;
                z-index: 2;
                left: 34px;
                font-size: 18px;

                + .tooltipText {
                    visibility: hidden;
                    display: inline-block;
                    color: #000;
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.36);
                    background-color: #ffffff;
                    text-align: center;
                    border-radius: 5px;
                    padding: 5px 10px;
                    
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 3;
                    top: 32px;
                    left: 4px;
                    font-size: 12.5px;
                    line-height: 1.52;
                    letter-spacing: 0.09px;
                }

                &:hover {
                    ~ .tooltipText{
                        visibility: visible;
                    }
                }
              }

            .asset-status {
                color: #ffffff;
                font-size: 14px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1.36;
                letter-spacing: 0.1px;

                .last_reporting_text {
                    font-size: 14px;
                    line-height: 1.36;
                    width: 198px;
                    letter-spacing: 0.09;
                }
            }

            .asset-address {
                color: #ffffff;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1.36;
                letter-spacing: 0.1px;
            }

            .asset-speed {
                color: #ffffff;
                font-size: 14px;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.36;
                letter-spacing: 0.1px;
            }

            .asset-direction-icon {
                i {
                    float: right;
                    width: 16px;
                    margin-left: 5px;
                    &.NE {
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }

                    &.E {
                        -ms-transform: rotate(90deg);
                        -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
                    }

                    &.SE {
                        -ms-transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                        transform: rotate(135deg);
                    }

                    &.S {
                        -ms-transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

                    &.SW {
                        -ms-transform: rotate(225deg);
                        -webkit-transform: rotate(225deg);
                        transform: rotate(225deg);
                    }

                    &.W {
                        -ms-transform: rotate(270deg);
                        -webkit-transform: rotate(270deg);
                        transform: rotate(270deg);
                    }

                    &.NW {
                        -ms-transform: rotate(315deg);
                        -webkit-transform: rotate(315deg);
                        transform: rotate(315deg);
                    }
                }
            }

            .landmark-header {
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                letter-spacing: 0.2px;
            }

            .landmark-address {
                color: #ffffff;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1.36;
                letter-spacing: 0.1px;
            }
        }
    }

    .direction-header{
        width: 324px;
        height: 21px;
        opacity: 0.87;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.4px;
        color: #000000;
    }

    .list-border {
        position: relative;
        width: 320px;
        opacity: 0.15;
        border: solid .6px #000000;
    }

    .direction-desc {
        width: 240px;
        font-size: 16px;
        letter-spacing: -0.1px;
        color: #4d4d4e;
    }

    .direction-distance-time {
        width: 60px;
        font-size: 16px;
        letter-spacing: -0.1px;
        color: #4d4d4e;
    }
}

.detailLoader {
    margin: 50px;
    text-align: center;
}

.gridContainer {
    margin-top: -12px;
    margin-left: -12px;

    >div {
        padding: 12px 4px 0px 12px !important;
    }
}

.directionDetailsGrid {
    padding: 3px 0px 4px 8px;
}

.gridItemDirections {
    padding-bottom: 12px;
}

.directionStepsList {
    padding: 10px 8px 0px 24px
}