@import "../../index.module";

.assetIconContainer {
  div[role="button"] {
    padding: 0
  }
}

.assetIconWithText {
  display: flex;
  align-items: center;

  .text {
    padding-bottom: 5px;
    padding-left: 4px;
  }
}

.messageText {
  color: rgb(176, 184, 191);
  display: inline-block;
  margin-top: 25px;
}

.assetIconMenuItem {
  padding-bottom: 2px !important;
  padding-top: 2px;
}

.assetIconWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  bottom: 2px;

  .icon {
    position: absolute;
    padding-left: 1px;
    padding-bottom: 2px;
  }
}


.lpZero {
  padding-left: 0px !important;
}

.contentCopyTitle {
  flex: auto;
  margin: 9px 8px 0px -26px;
  cursor: copy;
}

.contentCopyDisable {
  flex: auto;
  margin: 9px 8px 0px -26px;
  cursor: not-allowed;
}

.InputBoxBtnGrid {
  padding-left: 12px !important;
  padding-top: 16px !important;
}

.selectBoxClearBtnGrid {
  padding-left: 12px !important;
  padding-top: 44px !important;
}

.assetIdSidebySideItems {
  padding-left: 16px !important;
  padding-top: 16px !important;
}

.accountDropdown {
  padding-left: 36px !important;
}

.fuelCardDropdown {
  padding-left: 36px !important;
  padding-top: 30px !important;
}

.associationsDropdown {
  padding-left: 36px !important;
  padding-top: 28px !important;
}

.inputGrid {
  padding-left: 18px !important;
}

.inputNameGrid {
  padding: 12px 0px 12px 24px !important;
}

.gridItemPadding {
  padding: 8px !important;
}

.dateContainer {
  padding: 16px 0px 0px !important;
}

.assetIDContainer {
  margin: -8px 0px 0px -8px
}

.deviceSerialItem {
  padding: 16px 0px 0px 12px !important;
}

.camcoachItem {
  padding: 0px 0px 0px 30px  !important;
}

.odometerItem {
  padding: 24px 8px 8px 16px !important;
}

.initialOdometerItem {
  padding: 18px 0px 0px 4px !important;
}

.gridPadding {
  padding: 16px 8px 8px !important;
}

.lmDashcamGridPadding {
  padding: 0px 8px 8px !important;
  display: flex;
  align-items: center;
  height: 90px;
}

.lmDashcamLabel {
  font-size: 0.75rem;
  bottom: -5px;
  display: flex;
  height: 18px;
  z-index: 2;
}

.tbZeroPadding {
  padding: 0px !important;
  margin-bottom: -28px !important;
}

.gridNoPadding {
  margin-top: -25px !important;
}

.mt19px {
  margin-top: 19px !important;
}

.mt40px {
  margin-top: 40px !important;
}

.chipContainer {
  margin-top: 25px !important;
  padding-left: 22px !important;
  padding-bottom: 6px !important;
}

.inputsSBoxGrid {
  margin: -25px 0 0 -13px !important;
}

.inputsSelectGrid {
  margin-top: -15px !important;
  margin-left: 9px !important;
}

.safetyTblChkBox {
  padding: 0 12px 10px 0 !important;
}

.tooltipCursorPointer {
  cursor: pointer;
}

.cellularTest {
  opacity: 0.87;
  font-size: 15px !important;
  color: red !important;
  padding: 2px;
  line-height: normal !important;
}

.passed {
  color: #32CD32 !important;
}

.unknown {
  color: rgba(0, 0, 0, 0.87) !important;
  opacity: 0.4 !important;
}

.GPSTest { @extend .cellularTest }
.ignitionTest  { @extend .cellularTest }
.driverIDTest  { @extend .cellularTest }
.starterTest  { @extend .cellularTest }
.batteryTest { @extend .cellularTest }

.date {
  opacity: 0.4;
  font-size: 15px !important;
  padding: 2px;
  line-height: normal !important;
}

.GPSLocation { @extend .date }
.deviceSerial { @extend .date }
.installationType { @extend .date }
.installedLocation { @extend .date }
.installedDevices { @extend .date }
.batteryPercentage { @extend .date }

.installerNotes {
  opacity: 0.4;
  font-size: 15px !important;
  padding: 2px;
  line-height: normal !important;
  max-height: 120px;
  width: 100%;
  overflow: auto;
}

.cursorPointerNotAllowed {
  cursor: not-allowed;
}

.safetyCheckBoxWidth {
  width: 28px;
  height: 28px;
}

.toolbar {
 font-size: 2.125rem;
 padding: 16px 24px;
}

.safetyTblHideCheckBox {
  opacity: 0;
  pointer-events: none;
}

.safetyGridAlignment {
  padding-left: 35px;
  padding-bottom: 5px;
}
