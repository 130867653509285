@import "../../../index.module.scss";

.container {
  margin: 17px 16px !important;
  display: block !important;

  .title {
    width: 297px;
    padding: 18px 0px 0px 13px;

    .title_text {
      padding-left: 10px;
      white-space: nowrap;
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .settings_icon {
      height: 18px;
      padding-top: 5px;
      cursor: pointer;
    }
  }

  .event_card_header {
    border-bottom: solid 0.5px #979797;
  }

  .safety {
    padding-top: 15px;
  }

  .safety {
    padding-top: 15px;
  }

  .monitoring {
    padding-top: 26px;
  }

  .event_card {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 0.5px #979797;
    border-bottom: solid 0.5px #979797;
  }

  .event_safety_card_safety {
    @extend .event_card;
  }

  .event_safety_card_monitoring {
    @extend .event_card;
  }

  .event_safety_card_safety:nth-last-child(3n+2) {
    border-right: 0;
  }

  .event_safety_card_safety:nth-last-child(-n+1) {
    border-bottom: 0;
  }

  .event_safety_card_monitoring:nth-last-child(3n+1) {
    border-right: 0;
  }

  .event_safety_card_monitoring:nth-last-child(-n+3) {
    border-bottom: 0;
  }
  
  .event_count_card {
    margin-left: 23px;
    display: flex;
    align-items: center;

    .event_card_content {
      @extend .space-between;
      padding: 16px 24px;
      width: 100%;

      .total_text {
        font-size: 15px;
        text-transform: capitalize;

        .date_picker {
          font-family: inherit;
          font-size: 15px;
          letter-spacing: -0.4px;
          opacity: 0.95;
          left: -10px;  
        }
      }

      .view_all {
        @extend .center;
        background-color: $theme_primary_color;
        width: 67px;
        height: 20px;
        border-radius: 2px;
        color: $white;
        font-size: 12px;
        text-transform: uppercase;
        cursor: pointer;
      }

      .total_count {
        padding-left: 13px;
        font-size: 32px;
        line-height: 0.75;
        color: #000000;
      }

      .safety_total_count {
        padding-left: 52px;
        font-size: 32px;
        line-height: 0.75;
        color: #000000;
      }

      .monitoring_total_count {
        padding-left: 21px;
        font-size: 32px;
        line-height: 0.75;
        color: #000000;
      }

      .compare_count_content {
        padding-left: 8px;
        color: #000000;

        .count {
          font-size: 14px;
          line-height: 1,
        }

        .equal {
          margin: 0px 0px -3px 2px;
          line-height: 1;
          font-size: 18px;
        }

        .equal {
          width: 30px;
          height: 12px;
          margin: 13px 8px 12px 6px;
          line-height: 1.71;
          font-size: 14px;
        }

        .text {
          font-size: 10px;
          opacity: 0.5;
        }
      }
    }

    .event_count_header {

      .event_count_total_text {
        background-color: #3A83BA;
        color: #ffffff;
        padding-left: 8px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 10px;
      }
    }
   
    .total_events_text {
      font-size: 12px;
      letter-spacing: -0.4px;
      opacity: 0.73;
      padding-top: 5px;
    }
  }

  .event_list_card {
    width: 632px;
    margin-left: 23px;
  }
}

.paper {
  min-height: calc(100vh - 80px);
}

.list-style {
  min-height: 140px;
  padding-top: 0px;
  box-shadow: none !important;
}

.menuItem {
  padding: 14px 16px;
}
