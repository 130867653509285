@import "../../../index.module";

.address-block {
  @extend %container-block;

  li {
    @extend %container-block-li;

    div {
      @extend %container-block-div;
      min-height: 35px;
    }

    .oval {
      @extend %container-block-oval;

      .icon {
        width: 24px;
        height: 17px;
        object-fit: contain;
        position: relative;
        top: 14px;
        vertical-align: middle;
        background-position: 0;
        display: block;
        margin: auto;
      }
    }

    .main-address {
      height: 19px;
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      max-width: 200px;

      .main-address-header {
        font-family: $themeFont;
        font-weight: 400;
        color: $green;
      }

      .main-address-sub-header {
        color: #4a4a4a;
      }
    }

    .sub-address {
      height: 17px;
      opacity: 0.75;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      color: $green;
      position: relative;
      top: 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      max-width: 200px;

      .captilize {
        text-transform: uppercase;
      }
    }

    .list-icon-container {
      float: right;
      margin-top: 5px;

      div {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        position: relative;

        i {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }

        .map-icon-right {
          width: 18px;
          height: 18px;
          object-fit: contain;
          margin-right: 18px;
          background: url("../../../assets/icons/assets/map.png") no-repeat;
          display: table-cell;
          vertical-align: middle;
          background-position: 0;
        }

        .edit {
          width: 24px;
          height: 24px;
          object-fit: contain;
          margin-right: 33px;
          background: url("../../../assets/icons/assets/edit.png") no-repeat;
          display: table-cell;
          vertical-align: middle;
          background-position: 0;
        }

        .history {
          width: 18px;
          height: 18px;
          object-fit: contain;
          margin-right: 31px;
          background: url("../../../assets/icons/assets/history.png") no-repeat;
          display: table-cell;
          vertical-align: middle;
          background-position: 0;
        }

        .icon-span-text {
          position: relative;
          top: 20px;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.3px;
          color: $icon_txt;
          text-decoration: underline;
          cursor: pointer;
        }

        .icon-span-text-disabled {
          position: relative;
          top: 20px;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: -0.3px;
        }
      }

      div:last-child {
        margin-right: 0;
        cursor: pointer;
        outline: none;
      }

      div:first-child {
        cursor: pointer;
      }

      div.disabled {
        cursor: default;
        opacity: .3;
      }
      .listIconItem {
        min-width: unset;
      }
    }
  }
}

ul {
  margin-left: 16px;

  li {
    margin-bottom: 0px !important;
    padding-bottom: 3px !important;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: $green;

    span {
      i {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        &.idle {
          background-color: #f5a623;
        }

        &.stopped {
          background-color: #d91e18;
        }

        &.moving {
          background-color: #7ed321;
        }

        &.notreporting {
          background-color: #5D6D7E;
        }
      }
    }

    .tooltip {
      position: absolute;
      display: inline-block;
      top: 6px;
      z-index: 2;
      left: 44px;
      font-size: 18px;

      + .tooltipText {
          visibility: hidden;
          display: inline-block;
          color: #000;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.36);
          background-color: #ffffff;
          text-align: center;
          border-radius: 5px;
          padding: 5px 10px;

          /* Position the tooltip */
          position: absolute;
          z-index: 3;
          top: 40%;
          left: 18%;
          margin-left: -60px;
          font-size: 12.5px;
          line-height: 1.52;
          letter-spacing: 0.09px;
      }

      &:hover {
          ~ .tooltipText{
              visibility: visible;
          }
      }
    }
  }

  li:first-child {
    margin-top: 0
  }
}
