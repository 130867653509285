.container {
    height: 100vh;
    width: 100vw;
}
.content {
    height: 70vh;
    width: 80vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.contentHeight {
    overflow: scroll;
    max-height: 40vh;
}
.buttonContainer {
    float: right;
}
.errorMessage {
    background-color: #d3302f,
}
.icon {
    font-size: 20px,
}
.contentWrap > div {
    width: 98% !important;
    overflow: visible;
    * {
        overflow: visible;
    }
}
