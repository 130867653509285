@import "../../index.module";
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.banner {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.login {
  background: $white top center no-repeat;
}

.login-form-panel {
  height: 100vh;
  width: 42vw;
  min-width: 400px;
}

.zero-padding {
  padding: 0;
  display: flex;
  min-width: 700px;
}

.sweet-loading {
  position: absolute;
  top: 41%;
  left: 40%;
}

.ssotoken-loader {
  text-align: center;
  padding: 20px 0;
}

.ssotoken-loading {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.ssotoken-loading-container {
  width: 30%;
  padding: 30px;
}

.error {
  color: #f00 !important;
  opacity: 0.87;
  padding: 30px;
}
