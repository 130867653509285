@import "../../index.module";
.container {
    background: #ffff;
}
.landmark-details {
    display: flex;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    flex-grow: 1;
    height: 92vh;
    line-height: normal;
    letter-spacing: -0.7px;
    position:relative;
    overflow: hidden;
    z-index: 1;
    .address {
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        margin-bottom: 5px;
        opacity: 0.87;
    }

    .card {
        background-color: #343e48;
        border-radius: 0;
        box-shadow: none;
        height: 70px;
        position: absolute;
        width: 100%;
        .card-content {
            padding: 15px;
            .card-content-bottom {
                bottom: 0;
                position: absolute;
                width: 360px;
                .phone {
                    color: #ffffff;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: -0.4px;
                    opacity: 0.73;
                    width: 45%;
                }
            }
        }

        .card-content-header {
            padding: 8px 15px 2px 15px;

            .search-icon {
              background: #fff url("../../assets/icons/landmark_group/search-box-icon.svg") no-repeat center;
              border: none;
              cursor: pointer;
              display: inline-block;
              height: 48px;
              outline: none;
              padding-left: 15px;
              position: absolute;
              vertical-align: middle;
              width: 24px;
            }

            .search-input {
                border: none;
                display: inline-block;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                height: 48px;
                line-height: 1.43;
                letter-spacing: normal;
                margin-left: 39px;
                outline: none;
                padding: 0;
                width: 76%;
            }

            .search-input::placeholder {
                color: rgba(0, 0, 0, 0.38);
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: normal;
            }

            .close-btn {
                background: #fff url("../../assets/icons/landmark_group/close.svg") no-repeat;
                background-position: center;
                border: none;
                cursor: pointer;
                height: 48px;
                position: absolute;
                padding: 15px;
                padding-right: 28px;
                outline: none;
                width: 24px;
            }
        }
    }
}

.vehicle-count {
    padding-right: 0 !important;
    text-align: right;   
        span {
            color: rgba(0, 0, 0, 0.5) !important;
            font-size: 15px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: right;
        }
}
.list-item {
  padding: 17.5px 17.5px 0 17.5px;

  .landmark-avatar {
    i {
      background: url("../../assets/icons/addresses/shape.png") no-repeat;
      display: block;
      object-fit: contain;
      position: relative;
      vertical-align: middle;
    }
  }

  .landmark-name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    height: 19px;
    width: 194px;
  }

  .landmark-location {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 0.1px;
    text-transform: capitalize;
  }

  .landmark-details-right-side {
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

}
.display-msg {
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-family: $themeFontMediumBold;
    float: left;
    letter-spacing: -0.5px;
    line-height: 22px;
    opacity: 0.67;
    position: relative;
    padding-top: 20px;
    text-align: center;
    width: 100%;
    span {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: top;
    }
    .display-icon {
      display: inline-block;
      margin-right: 10px;
      padding-top: 2px;
      height: 20px;
      width: 20px;
    }
  }

.list-item {
  padding: 15px !important;
  .list-item-icon {
    height: 20px;
    width: 15px;
  }
  align-items: center;
}

.list-border {
  background: rgba(0, 0, 0, 0.5);
  left: 72px;
  height: 1px;
  opacity: 0.5;
  position: absolute;
  width: 79%;
}

.filter-chip {
    margin: 8px 6px 3px 6px;
}
