@import "../../../index.module";

.address-block {
  @extend %container-block;
  li {
    @extend %container-block-li;
    div {
      @extend %container-block-div;
      }
    .oval {
      @extend %container-block-oval;
      .icon {
        width: 14px;
        height: 20px;
        object-fit: contain;
        position: relative;
        top: 12px;
        background: url("../../../assets/icons/addresses/shape.png") no-repeat;
        vertical-align: middle;
        background-position: 0;
        display: block;
        margin: auto;
      }
    }
    .main-address {
      height: 19px;
      font-family: $themeFont;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      color: $green;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      max-width: 200px;
    }
    .sub-address {
      height: 17px;
      opacity: 0.75;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      color: $green;
      position: relative;
      top: 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      max-width: 200px;
    }
    .list-icon-container {
      float: right;
      margin-top: 0px;
      min-height: 35px;
      div {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        position: relative;
        i {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        .map-icon-right {
          width: 18px;
          height: 18px;
          object-fit: contain;
          margin-right: 18px;
          background: url("../../../assets/icons/addresses/map.png") no-repeat;
          display: table-cell;
          vertical-align: middle;
          background-position: 0;
        }
        .assets-icon-right {
          width: 18px;
          height: 18px;
          object-fit: contain;
          margin-right: 33px;
          background: url("../../../assets/icons/addresses/nearest-asset.png") no-repeat;
          display: table-cell;
          vertical-align: middle;
          background-position: 0;
        }
        .nearest-landmark {
          width: 18px;
          height: 18px;
          object-fit: contain;
          margin-right: 31px;
          background: url("../../../assets/icons/addresses/nearest-landmark.png") no-repeat;
          display: table-cell;
          vertical-align: middle;
          background-position: 0;
        }
        .icon-span-text {
          position: relative;
          top:19px;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.3px;
          color: $icon_txt;
          text-decoration: underline;
          cursor: pointer;
        }

      }
      div:last-child{
        margin-right: 0;
        cursor: pointer;
        outline: none;
      }
      .listIconItem {
        min-width: unset;
      }

    }

  }

}

    ul {
      margin-left: 16px;
      li {
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: $green;
      }
    }


