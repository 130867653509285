.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.videoContainer {
    width: 560px;
    position: relative;

    .playPauseButton {
        width: 100%;
        height: 275px;
        position: absolute;
        background: transparent;
        z-index: 2;

        .icon {
            @extend .center;
            width: 100%;
            height: 275px;

            .playPauseIcon {
                font-size: 95px;
                padding-top: 38px;
            }

            .pauseIcon {
                display: none;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.65);

                .pauseIcon {
                    display: inline-block;
                }
            }

            .text {
                padding-top: 38px;
                padding-left: 46px;

                p {
                    @extend .center;
                    color: #fff;
                    width: 210px;
                }
            }
        }
    }

    .video {
        width: 100%;
        background-color: black;
        max-width: 100%;
        height: 314px;
    }

    .video_player_loader {
        background-image: url("../../../assets/icons/loader.gif");
        background-repeat: no-repeat;
        background-size: 30px 30px;
        background-position: center;
    }

    .videoControls {
        right: 0;
        left: 0;
        padding: 0px 10px 4px 10px;
        position: absolute;
        transition: all 0.2s ease;
        background-color: rgba(0, 0, 0, 0.65);
        height: 35px;
        bottom: 4px;

        .bottomControls {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .leftControls {
                display: flex;
                align-items: center;
                color: #fff;
            }

            .rightControls {
                color: #fff;

                .button {
                    outline: none;
                    padding-right: 10px;

                    .svg {
                        font-size: 16px;
                    }
                }

                .disabled {
                    cursor: not-allowed;
                }
            }
        }
    }
}

.menu {
    margin-left: -62px;

    .selected {
        color: #007aff;
    }

    .menuItem {
        font-size: 16px;
        line-height: 1.5;
    }
}

.paperWidth {
    width: 94px;
}

.hide {
    opacity: 0;
    pointer-events: none;
}

.liveVideoContainer {
    padding: 16px 17px 0px 23px;
    min-width: 668px;

    .content {
        height: 366px;

        .card {
            width: 560px;
            padding-left: 18px;

            .nameContainer {
                display: flex;
                font-size: 14.4px;
                color: rgba(0, 0, 0, 0.87);
                padding: 9px 0px;

                .name {
                    flex: auto;
                }

                .removeVideo {
                    justify-content: flex-end;
                    align-items: flex-end;
                    font-weight: bold;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}
