@import "../../../index.module";

.container {
    .available_assets {
        display: block;
        .available_assets_list {
            background-color: #fff;
            height: calc(100vh - 196px);
        }
    }
    .asset_count_text {
        height: 16px;
        font-family: $themeFont;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #007aff;
        padding: 10px 0px 5px 13px
    }
}
