.formField {
  position: relative;
  margin: 0 !important;
  width: 95% !important;
  max-width: 350px !important;
}

.fullWidth {
  width: 100%;
  display: block;
}

.plansDesc {
  margin-top: 20px !important;
  overflow: auto;
}

.firstCol {
  border-right: 1px solid #979797 !important;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.placeholder {
  color: rgba(0, 0, 0, 0.35) !important;
}

.helpIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #767676 !important;
}

.helpIcon {
  font-size: 16px !important;
  align-items: center;
  justify-content: center;
  color: #767676 !important;
  margin-bottom: -2px;
  margin-left: 5px;
}

.main {
  margin: 12px 0 !important;
}

.items {
  margin-bottom: 12px !important;
}

.textField {
  max-width: 350px;
  width: 85%;
  padding-top: 0 !important;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}

.datePickerBox {
  max-width: 350px;
  width: 85%;
  padding-top: 0 !important;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
  display: inline-flex;
}

.datePicker {
  width: 100%;
}

.fieldTitle {
  font-size: 20px !important;
  white-space: nowrap;
}

.subtitle {
  font-size: 15px !important;
  white-space: nowrap;
}

.titleRow {
  padding-left: 0 !important;
}

.keyRow {
  padding-left: 42px !important;
}

.borderlessRow {
  border-bottom: none !important;
}

.radioList {
  padding-left: 0;
  margin-left: 0;
}

.backButton {
  vertical-align: middle;
  padding-right: 10px;
  margin-left: -12px;
}

.header {
  background-color: #343e48;
  color: #ffffff;
}

.footNoteText {
  font-size: 12px;
}

.divider {
  border-radius: 2px;
}

.planSelect {
  max-width: 30ch;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.gridItem {
  padding: 16px !important;
}