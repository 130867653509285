.container {
  .toolbar {
    color: #4a4a4a;
    padding-left: 20px;

    .backButton {
      vertical-align: middle;
      padding-right: 10px;
      margin-left: -12px;
    }

    .eventTitle {
      line-height: 1.0;
      font-size: 1.0rem;
    }

    .eventSubTitle {
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .delayMessage {
    padding-top: 20px;
    padding-left: 20px;
  }

  .rearDelayMessage {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .video_header {
    width: 540px;
    height: 35px;
    background-color: black;
    box-shadow: none;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 14.4px;
      font-weight: 500;
      letter-spacing: normal;
      color: rgba(255, 255, 255, 0.87);
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .event_video_container {
    padding-left: 20px;
   }
}
