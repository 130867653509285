@import "../../../index.module";

.address-block {
  @extend %container-block;
  li {
    @extend %container-block-li;
    div {
      @extend %container-block-div;
      min-height: 35px;
      max-width: 75%;
    }
    .oval {
      @extend %container-block-oval;
      .icon {
        width: 24px;
        height: 21px;
        -o-object-fit: contain;
        object-fit: contain;
        position: relative;
        top: 11px;
        left: 3px;
        background: url("../../../assets/icons/drivers/drivers.png") no-repeat;
        vertical-align: middle;
        background-position: 0;
        display: block;
        margin: auto;
      }
    }
    .main-address {
      height: 19px;
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      display: block;
      .main-address-header{
        font-family: $themeFont;
        font-weight: 400;
        color: $green;
        display: inline-block;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 2px 0 0;
      }
      .main-address-sub-header{
        color: #4a4a4a;
        .hyphen{
          display: inline-block;
          width: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .vehicle-assigned{
          display: inline-block;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #007aff;
          padding: 0 0 0 2px;
        }
      }
    }
    .sub-address {
      height: 17px;
      opacity: 0.75;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      color: $green;
      position: relative;
      top: 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      max-width: 200px;
    }
    .list-icon-container {
      float: right;
      margin-top: 5px;
      div {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        position: relative;
        outline: none;
        i {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        .edit {
          width: 18px;
          height: 18px;
          object-fit: contain;
          margin-right: 18px;
          background: url("../../../assets/icons/assets/edit.png") no-repeat;
          cursor: pointer;
          display: table-cell;
          vertical-align: middle;
          background-position: 0;
        }
        .score-copy {
          width: 18px;
          height: 18px;
          object-fit: contain;
          margin-right: 33px;
          background: url("../../../assets/icons/drivers/score-copy.png") no-repeat;
          cursor: pointer;
          display: table-cell;
          vertical-align: middle;
          background-position: 0;
        }
        .contact-copy {
          width: 18px;
          height: 18px;
          object-fit: contain;
          margin-right: 31px;
          background: url("../../../assets/icons/drivers/contact-copy.png") no-repeat;
          display: table-cell;
          vertical-align: middle;
          background-position: 0;
        }

        .icon-span-text {
          position: relative;
          top:19px;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.3px;
          color: $icon_txt;
          text-decoration: underline;
          cursor: pointer;
        }

        .icon-span-text {
          position: relative;
          top:19px;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.3px;
          color: $icon_txt;
          text-decoration: underline;
          cursor: pointer;
        }

      }
      div:last-child{
        margin-right: 0;
        cursor: pointer;
        outline: none;
      }
      div:first-child{
        cursor: pointer;
      }
      .listIconItem {
        min-width: unset;
      }

    }

  }

}

    ul {
      margin-left: 16px;
      li {
        margin-bottom: 0px !important;
        padding-bottom: 3px !important;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: $green;
        span{
          i{
            width: 9px;
            height: 9px;
            border-radius: 50%;
            display: inline-block;
            &.idle-class {
              background-color: #f5a623;
            }
          }
        }
      }
    }



