@import "../../index.module";

.placeholder {
    color: #b0b8bf !important;
    font-weight: normal;
}
.optionClass {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
