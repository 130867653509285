.pieContainer {
    position: relative;
}

.pie {
    transition: all 1s;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    clip: rect(0px, 24px, 58px, 0px);
}

.hold {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    clip: rect(0px, 48px, 48px, 17px);
}

.pieSlice1 {
    transform: rotate(0deg);
}

.pieSlice1 .pie {
    background-color: #1b458b;
    transform: rotate(100deg);
}

.pieSlice2 {
    transform: rotate(100deg);
}

.pieSlice2 .pie {
    background-color: #0a0;
    transform: rotate(100deg);
}

.pieSlice3 {
    transform: rotate(200deg);
}

.pieSlice3 .pie {
    background-color: #f80;
    transform: rotate(160deg);
}

.innerCircle {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #007aff;
    border-radius: 50%;
    top: 5px;
    left: 5px;
    // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5) inset;
    color: white;
}

.content {
    position: absolute;
    display: block;
    width: 30px;
    top: 11px;
    left: 9px;
    text-align: center;
    font-size: 11.2px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #ffffff;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;

}

.landmark_content {
    position: absolute;
    display: block;
    width: 35px;
    top: 12px;
    left: 10px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #ffffff;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;

}


