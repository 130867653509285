@import "../../../index.module";

.container {
    .available_cameras {
        display: block;
        .available_cameras_list {
            background-color: #fff;
            height: calc(100vh - 196px);
        }
    }
}
