@import "../../index.module";

      .container-fluid .row {
        padding: 0 0 4em 0;
        }
        .container-fluid .row:nth-child(even) {
        background: #F1F4F5;
        }
        
        .example-title {
        text-align: center;
        margin-bottom: 60px;
        padding: 3em 0;
        border-bottom: 1px solid #E4EAEC;
        }
        .example-title p {
        margin: 0 auto;
        font-size: 16px;
        max-width: 400px;
        }
        
        /*==================================
          TIMELINE
        ==================================*/
        /*-- GENERAL STYLES
        ------------------------------*/
        .timeline {
        line-height: 1.4em;
        list-style: none;
        margin: 16px 0px 16px 0px;
        padding: 0;
        width: 100%;
        }
        .timeline h3{
            margin: 5px 0;
        }
        /* .timeline h1, .timeline h2, .timeline h3, .timeline h4, .timeline h5, .timeline h6 {
        line-height: inherit;
        } */
        
        /*----- TIMELINE ITEM -----*/
        .timelineItem {
        padding-left: 40px;
        position: relative;
        }
        .timelineItem:last-child {
        padding-bottom: 0;
        }
        
        /*----- TIMELINE INFO -----*/
        .timelineInfo {
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          margin: 0 0 .5em 0;
          text-transform: uppercase;
          white-space: nowrap;
          color: #000000;
          padding-left: 16px;
        }
        
        /*----- TIMELINE MARKER -----*/
        .timelineFirst {
          height: 50px !important;
        }
        .timelineMarker {
        // position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 15px;
        }
        .timelineMarker:before {
        /* background: #FF6B6B; */
        border: 1px solid transparent;
        border-radius: 100%;
        content: "";
        display: block;
        height: 10px;
        position: absolute;
        top: 7px;
        left: 0;
        width: 10px;
        z-index: 1;
        /* transition: background 0.3s ease-in-out, border 0.3s ease-in-out; */
        }

        .timelineMarker.green:before {
            background:#8bc34a;;
        }

        .timelineMarker.red:before {
            background:#f44336;
        }

        .timelineMarker:after {
            content: "";
            width: 3px;
            display: block;
            position: absolute;
            top: 7px;
            bottom: -9px;
            left: 5px;
            border-left: 1.5px dashed rgba(0, 0, 0, 0.63);
            
        }
        .timelineItem:last-child .timelineMarker:after {
        content: none;
        }
        
        /* .timelineItem:not(.period):hover .timelineMarker:before {
        background: transparent;
        border: 3px solid #FF6B6B;
        } */
        
        /*----- TIMELINE CONTENT -----*/
        .timelineContent {
        padding-bottom: 20px;

        }
        .timelineContent span {
            margin: 5px 0;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.63);
            white-space: normal;
            display: inline;
           
        }
        .timelineContent span:last-child {
        margin-bottom: 0;
        }
        
        /*----- TIMELINE PERIOD -----*/
        .period {
        padding: 0;
        }

        .period .timelineInfo {
        display: none;
        }

        .period .timelineMarker:before {
        background: transparent;
        content: "";
        width: 15px;
        height: auto;
        border: none;
        border-radius: 0;
        top: 0;
        bottom: 30px;
        position: absolute;
        border-top: 3px solid #CCD5DB;
        border-bottom: 3px solid #CCD5DB;
        }
        .period .timelineMarker:after {
        content: "";
        height: 32px;
        top: auto;
        }

        .period .timelineContent {
        padding: 40px 0 70px;
        }

        .period .timeline-title {
        margin: 0;
        }
        
        /*----------------------------------------------
          MOD: TIMELINE SPLIT
        ----------------------------------------------*/
        @media (min-width: 768px) {
          .timelineSplit .timeline, .timeline-centered .timeline {
            display: table;
          }

          .timelineSplit .timelineItem, .timeline-centered .timelineItem {
            display: table-row;
            padding: 0;
          }

          .timelineSplit .timelineInfo, .timeline-centered .timelineInfo,
          .timelineSplit .timelineMarker,
          .timeline-centered .timelineMarker,
          .timelineSplit .timelineContent,
          .timeline-centered .timelineContent,
          .timelineSplit .period .timelineInfo,
          .timeline-centered .period .timelineInfo {
            display: table-cell;
          }

          .timelineSplit .timelineMarker, .timeline-centered .timelineMarker {
            position: relative;
          }

          .timelineSplit .timelineContent, .timeline-centered .timelineContent {
            padding-left: 30px;
            padding-bottom: 6px;
          }

          .timelineSplit .timelineInfo, .timeline-centered .timelineInfo {
            padding-right: 15px;
          }

          .timelineSplit .period .timeline-title, .timeline-centered .period .timeline-title {
            position: relative;
            left: -45px;
          }
        }
        
        .icon{
            position: absolute;
            z-index: 100;
            top: 5px;
            left: 4px;
            font-size: 27px;
        }
        .timelineMarker.large{
            width: 35px;
            background: #ccc;
        }
        .timeline .timelineItem:first-child .timelineMarker.large {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }
        .timeline .timelineItem:last-child .timelineMarker.large {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        .status .timelineContent {
            padding-bottom: 20px;
        }
        
        /*----------------------------------------------
          MOD: TIMELINE CENTERED
        ----------------------------------------------*/
        @media (min-width: 992px) {
          .timeline-centered,
          .timeline-centered .timelineItem,
          .timeline-centered .timelineInfo,
          .timeline-centered .timelineMarker,
          .timeline-centered .timelineContent {
            display: block;
            margin: 0;
            padding: 0;
          }

          .timeline-centered .timelineItem {
            padding-bottom: 40px;
            overflow: hidden;
          }

          .timeline-centered .timelineMarker {
            position: absolute;
            left: 50%;
            margin-left: -7.5px;
          }

          .timeline-centered .timelineInfo,
          .timeline-centered .timelineContent {
            width: 50%;
          }

          .timeline-centered > .timelineItem:nth-child(odd) .timelineInfo {
            float: left;
            text-align: right;
            padding-right: 30px;
          }

          .timeline-centered > .timelineItem:nth-child(odd) .timelineContent {
            float: right;
            text-align: left;
            padding-left: 30px;
          }

          .timeline-centered > .timelineItem:nth-child(even) .timelineInfo {
            float: right;
            text-align: left;
            padding-left: 30px;
          }

          .timeline-centered > .timelineItem:nth-child(even) .timelineContent {
            float: left;
            text-align: right;
            padding-right: 30px;
          }

          .timeline-centered > .timelineItem.period .timelineContent {
            float: none;
            padding: 0;
            width: 100%;
            text-align: center;
          }

          .timeline-centered .timelineItem.period {
            padding: 50px 0 90px;
          }

          .timeline-centered .period .timelineMarker:after {
            height: 30px;
            bottom: 0;
            top: auto;
          }

          .timeline-centered .period .timeline-title {
            left: auto;
          }
        }
        
        /*----------------------------------------------
          MOD: MARKER OUTLINE
        ----------------------------------------------*/
        .marker-outline .timelineMarker:before {
        background: transparent;
        border-color: #FF6B6B;
        }
        
        .marker-outline .timelineItem:hover .timelineMarker:before {
        background: #FF6B6B;
        }

        .alerts-content{
          width: 268px;
          height: 48px;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.63);
          white-space: normal;
        }

        .address-line1 {
          white-space: nowrap !important;
        }

        .address-state-city {
          padding-top: 4px;
          white-space: normal;
        }
