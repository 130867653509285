@import "../../../index.module";

.main {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.main td {
    margin: 0 !important;
    padding: 0 !important;
}

.main tr {
    margin: 0 !important;
    padding: 0 !important;
}

.header {
    width: 100%;
    height: 66px;
    background: #343e48;
    color: white;
    display: inline-block;
}

.footer {
    flex-wrap: nowrap;
    height: 63px;
    padding: 14px;
}

table { width:100%; }

.header p {
    height:  20px;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.foot-note-text {
    font-size: 12px;
}
