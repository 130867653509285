.dashcam_card_holder {
    margin-bottom: 2px;
    position: relative;

    .dashcamCard {
        border-radius: 0px;
        box-shadow: none;
        cursor: pointer;
        padding-left: 6px;

        .dashcamCardHeader {
            padding: 10px 8px 7px 10px;

            .dashcam_recording_icon_container {
                display: flex;
                position: relative;
                top: -17px;

                .dashcam_recording_icon {
                    margin-right: -3px;
                    position: relative;
                    top: -1px;
                }
            }

            .tooltip {
                display: inline-block;
                font-size: 18px;
                left: 46px;
                position: absolute;
                top: 15px;
                z-index: 2;
            }
        }
    }

    .selected_card {
        background-color: #ADDCFF;
    }

    .dashcamCard:hover {
        background-color: #d8d8d8;
    }

    .dashcam_name_btn {
        outline: none;
        padding: 0;

        .dashcam_name {
            color: rgba(0, 0, 0, 0.87);
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.15px;
            text-align: left;
            max-width: 210px;
        }
    }

    .dashcam_action_btn_container {
        display: inline-flex;
        position: absolute;
        right: 15px;

        svg {
            height: 16px;
            margin-left: 13px;
            position: relative;
            top: 2px;
        }
    }

    .dashcam_live_view_container {
        left: 16px;
        margin-top: 25px;
        position: relative;

        h3 {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.15px;
            line-height: 20px;
        }

        svg {
            float: right;
            height: 16px;
            position: relative;
            right: 60px;
            top: -16px;
        }
    }
}
