@import "../../../index.module";

.search-header {
    position:relative;
    flex-grow: 1;
    z-index: 1;
    overflow: hidden;
    display: flex;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.7px;
    min-height: 90px;

    .asset-group-header {
        font-size: 24px;
        color: #ffffff;
    }

    .sub-header {
        display: inline-block;
        opacity: 0.73;
        font-family: inherit;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #ffffff;
        margin-bottom: 5px;
    }

    .card {
        min-height: 70px;
        background-color: #343e48;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 70% 50%;
        position: relative;
        box-shadow: none;
        border-radius: 0;
        .card-content {
            padding: 15px;
        }
        .card-content-header {
            padding: 8px 15px 2px 15px;
        }
    }

    .search-icon-white {
        background: url("../../../assets/icons/search-white.svg") no-repeat center;
        height: 27px;
        width: 18px;
        float: right;
        cursor: pointer;
        outline: none;
        border: none;
    }

    .filter-icon-white {
        background: url("../../../assets/icons/filter-icon.svg") no-repeat center;
        height: 27px;
        width: 18px;
        float: right;
        margin-left: 15px;
        cursor: pointer;
        outline: none;
        border: none;
    }

    .filter-icon-blue {
        background: url("../../../assets/icons/filter-icon-blue.svg") no-repeat center;
        height: 27px;
        width: 18px;
        float: right;
        margin-left: 15px;
        cursor: pointer;
        outline: none;
        border: none;
    }

    .asset-subtext {
        width: 121px;
        height: 38px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.36;
        letter-spacing: 0.1px;
        color: gray;
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0.64);
    }

    .search-box {
        height: 48px;
        width: 342px;
        background-color: white;
        display: inline-block;
        border-radius: 2px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);

        .asset-search-input {
            vertical-align: middle;
            display: inline-block;
            padding-top: 8px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;

            input {
                font-size: 14px;
            }

            .search-icon {
                width: 18px;
                height: 18px;
                object-fit: contain;
                background: url("../../../assets/icons/search.svg") no-repeat;
                display: table-cell;
                vertical-align: middle;
                margin-left: 16px;
                margin-right: 15px;
            }

            .cancel-search {
                width: 14px;
                height: 14px;
                object-fit: contain;
                background: url("../../../assets/icons/close.svg") no-repeat;
                display: inline-block;
                vertical-align: middle;
                margin-right: 12px;
                cursor: pointer;
            }
        }
    }
    .search-box-container {
        height: 80px;
        width: 342px;
        position:relative;
        flex-grow: 1;
        overflow: hidden;
        .sub-search-count {
            opacity: 0.73;
            font-family: inherit;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.4px;
            color: #ffffff;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}
